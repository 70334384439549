import 'reactflow/dist/style.css';

import ReactFlow, {
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    useNodesInitialized,
    addEdge,
    useReactFlow,
    useViewport,
    useOnViewportChange,
    MiniMap,
    Controls,
    Background,
    ReactFlowInstance,
    applyEdgeChanges,
    SelectionMode,
    useOnSelectionChange,
} from 'reactflow';
import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import 'reactflow/dist/style.css';
import './Flow.css';
import {useSetRecoilState, useRecoilValue} from 'recoil';
import produce from 'immer';
import ProjectState from '@recoil/ProjectState';
import FlowFunctionState from '@recoil/FlowFunctionState';
import {interval, BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
    debounceTime, tap, distinctUntilChanged, bufferTime,
    shareReplay,
    exhaustMap,
    take,
    startWith,

} from "rxjs/operators";
import {FlowNodeTypeComponentMap} from "@map/FlowNodeTypeComponentMap";
import FlowFunctionInterface from "@interface/FlowFunctionInterface"
import {FLOW_NODE_TYPE} from '@type/FlowNodeTypes';
import ConsoleUtil from '@util/ConsoleUtil';
import {Node} from "@reactflow/core/dist/esm/types/nodes";
import UuidUtil from "@util/UuidUtil";

// interface BaseFlowInterface extends RenderInterface{
//   flowData: FlowDataInterface
// }

function BaseFlow(props) {

    // console.log(Math.random())
    const updateFlowData = useSetRecoilState(ProjectState.updateFlowDataRenderList)
    const updateFunction = useSetRecoilState(FlowFunctionState.update)

    const flowMap = useRecoilValue(ProjectState.flowMap)
    const flowData = flowMap[props.uuid]
    const initialNodes = flowData.render.nodes
    const initialEdges = flowData.render.edges
    const initialViewport = flowData.render.viewport

    const [uuid, setUuid] = useState(flowData.uuid);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [rfInstance, setRfInstance] = useState(null);

    const {setViewport} = useReactFlow();
    const {x, y, zoom} = useViewport();

    useOnSelectionChange({
        onChange: ({ nodes, edges }) => console.log('changed selection', nodes, edges),
    });
      
    useOnViewportChange({
        onStart: useCallback((viewport) => null, []),
        onChange: useCallback((viewport) => null, []),
        // onEnd: useCallback((viewport) => null, []),
        onEnd: useCallback((viewport) => updateViewPort(viewport), []),
    });

    //  TODO:: 무시 되는 데이터가 발생. 특정 시간 동안 입력시간이 없으면 마지막 것 추가하도록 설정.
    const flowPipe$ = useMemo(() => new BehaviorSubject({}), []);
    const flowShared = flowPipe$.pipe();

    const functionPipe$ = useMemo(() => new BehaviorSubject({}), []);
    const functionShared = functionPipe$.pipe();

    useEffect(() => {
        const DUE_TIME = 200;
        const COUNT = 100;
        const subscription = flowPipe$
            .pipe(
                exhaustMap((val) =>
                    flowShared.pipe(
                        debounceTime(DUE_TIME), startWith(val), take(COUNT),
                        tap(val => updateNodesEdgesViewPort(val)),
                    )
                )
            )
            .subscribe();
        return () => subscription.unsubscribe();
    }, [uuid, setUuid]);



    const updateNodesEdgesViewPort = useCallback((val) => {
        const {nodes, edges, viewport} = val
        if (!nodes) {
            return
        }
        if (!edges) {
            return
        }
        if (!viewport) {
            return
        }
        if (flowData === undefined) {
            return
        }
        if (flowData.uuid !== uuid) {
            return
        }
        // ConsoleUtil.log(`update a`, nodes)

        // ConsoleUtil.log(edges)
        // ConsoleUtil.log(viewport)

        const newFlowData = produce(flowData, draft => {
            draft.render.nodes = nodes
            draft.render.edges = edges
            draft.render.viewport = viewport
        })
        // ConsoleUtil.log(`update b`, newFlowData)

        updateFlowData([newFlowData])
    }, [uuid, setUuid, updateFlowData]);


    // setInterval(function () {return flowData$.next({ nodes, edges, viewport: { x, y, zoom } })}, 1000)
    const onNodesChangeGroup = useCallback((evt) => {
        // updateNodesEdgesViewPort()
        // ConsoleUtil.log(`onNodesChangeGroup`, nodes)
        flowPipe$.next({nodes, edges, viewport: {x, y, zoom}});
        return onNodesChange(evt)
    }, [uuid, setUuid, nodes, edges, x, y, zoom]);


    const onEdgesChangeGroup = useCallback((evt) => {
        // onEdgesChange(evt)
        // updateNodesEdgesViewPort()
        // ConsoleUtil.log(`onEdgesChangeGroup`)
        flowPipe$.next({nodes, edges, viewport: {x, y, zoom}});
        return onEdgesChange(evt)
    }, [uuid, setUuid, nodes, edges, x, y, zoom]);

    // const onViewportChangeGroup = (evt) => {
    //   updateNodesEdgesViewPort(evt)
    // }

    const updateViewPort = useCallback((viewport) => {
        ConsoleUtil.log(`updateViewPort`, {nodes, edges, viewport: viewport})
        // ConsoleUtil.log(JSON.stringify(viewport))
        const newFlowData = produce(flowData, draft => {
            draft.render.viewport = viewport
        })
        if (nodes === initialNodes) return

        flowPipe$.next({nodes, edges, viewport: viewport});
        // updateFlowData([newFlowData])
    }, [uuid, setUuid, nodes, edges, x, y, zoom]);

    const addTableNode = useCallback(() => {
        const id = UuidUtil.getUuid()
        const newNode = {
            id: id,
            type: FLOW_NODE_TYPE.TABLE,
            data: {
                label: 'Add node',
                flowUuid: uuid,
                flowNodeUuid: id,
            },
            position: {
                x: Math.random() * window.innerWidth - 100,
                y: Math.random() * window.innerHeight,
            },
        };
        setNodes((nds) => nds.concat(newNode));
    }, [setNodes, uuid]);

    const addMemoNode = useCallback(() => {
        const id = UuidUtil.getUuid()
        const newNode = {
            id: id,
            type: FLOW_NODE_TYPE.MEMO,
            data: {
                label: 'Add node',
                flowUuid: uuid,
                flowNodeUuid: id,
            },
            position: {
                x: Math.random() * window.innerWidth - 100,
                y: Math.random() * window.innerHeight,
            },
        };
        setNodes((nds) => nds.concat(newNode));
    }, [setNodes, uuid]);

    const addGroupNode = useCallback(() => {
        const id = UuidUtil.getUuid()
        const newNode = {
            id: id,
            type: FLOW_NODE_TYPE.GROUP,
            data: {
                label: 'Add node',
                flowUuid: uuid,
                flowNodeUuid: id,
            },
            position: {
                x: Math.random() * window.innerWidth - 100,
                y: Math.random() * window.innerHeight,
            },
        };
        setNodes((nds) => nds.concat(newNode));
    }, [setNodes, uuid]);

    const getNodesInside = useCallback((object: Node, subjectArray: Array<Node>): Array<Node> => {
        if (object.width == null) return [];
        if (object.height == null) return [];

        const startX = object.position.x;
        const endX = object.position.x + object.width;
        const startY = object.position.y;
        const endY = object.position.y + object.height;

        // ConsoleUtil.log(`startX, endX`, startX, endX)
        // ConsoleUtil.log(`startY, endY`, startY, endY)

        const nodesInside = subjectArray.filter((subject: Node) => {
            // ConsoleUtil.log(`n.id`, subject.id)
            // ConsoleUtil.log(`n.id`, subject)
            // ConsoleUtil.log(`n.position.x`, subject.position.x)
            // ConsoleUtil.log(`n.position.y`, subject.position.y)

            if (subject.width == null) return;
            if (subject.height == null) return;

            const startTargetX = subject.position.x;
            const endTargetX = subject.position.x + subject.width;
            const startTargetY = subject.position.y;
            const endTargetY = subject.position.y + subject.height;

            return (
                startX < startTargetX &&
                endX > startTargetX &&
                startX < endTargetX &&
                endX > endTargetX &&
                startY < startTargetY &&
                endY > startTargetY &&
                startY < endTargetY &&
                endY > endTargetY &&
                subject.id !== object.id
            )
        });

        return nodesInside
    }, [uuid, setUuid])

    const updateGroupRelation = useCallback((evt, nodeDragged: Node) => {
        ConsoleUtil.log(`updateGroupRelation`)
        const nodesToUpdate: Partial<Node>[] = []

        nodes.forEach((node: Node) => {
            const nodesInside: Array<Node> = getNodesInside(node, nodes)
            // ConsoleUtil.log(`nodesInside`, node, nodesInside)

            if (node.type !== FLOW_NODE_TYPE.GROUP) return
            const newNode = {
                id: node.id,
                data: {
                    include: {
                        idMap: {}
                    }
                }
            }

            nodesInside.forEach((subject: Node) => {
                newNode.data.include.idMap[subject.id] = {data: subject.data.flowNodeUuid};
            })
            nodesToUpdate.push(newNode)
        })

        const nodesUpdated = produce(nodes, draftArray => {
            nodesToUpdate.forEach((nodePartialToUpdate: Partial<Node>) => {
                const draftIndex = draftArray.findIndex((curr) => curr.id === nodePartialToUpdate.id);
                if (draftIndex === -1) return;
                if (nodePartialToUpdate.id == undefined) return;

                draftArray[draftIndex].data.include = nodePartialToUpdate.data.include;
            })
        })

        setNodes(nodesUpdated);
    }, [uuid, setUuid, nodes, setNodes, x, y, zoom]);


    useEffect(() => {
        const DUE_TIME = 200;
        const COUNT = 100;
        const subscription = functionPipe$
            .pipe(
                exhaustMap((val) =>
                    functionShared.pipe(
                        debounceTime(DUE_TIME), startWith(val), take(COUNT),
                        tap(val => updateFunction({
                            flowMap: {
                                [props.uuid]: {
                                    addTableNode: addTableNode,
                                    addMemoNode: addMemoNode,
                                    addGroupNode: addGroupNode,
                                    updateGroupRelation: updateGroupRelation,
                                }
                            }
                        })),
                    )
                )
            )
            .subscribe();
        return () => subscription.unsubscribe();
    }, [uuid, setUuid]);



    useEffect(() => {
        // console.log(`useEffect > uuid, setUuid, nodes, setNodes`)
        // const newFlowFunctionState: FlowFunctionInterface = {
        //     flowMap: {
        //         [props.uuid]: {
        //             addTableNode: addTableNode,
        //             addMemoNode: addMemoNode,
        //             addGroupNode: addGroupNode,
        //             updateGroupRelation: updateGroupRelation,
        //         }
        //     }
        // }

        functionPipe$.next({});

        // updateFunction(newFlowFunctionState)
    }, [uuid, setUuid]);

    const reactFlow = ((props) => {
        let isVisible = flowData.isVisible

        if (!isVisible) {
            return <></>
        }

        // TODO :: SELECT MODE
        // panOnDrag={false}
        // selectionOnDrag={true}
        return <>
            <ReactFlow

                minZoom={0.1}
                // maxZoom={1.5}
                // selectionOnDrag={true} // TODO :: SELECT MODE
                // selectNodesOnDrag={true} // TODO :: SELECT MODE
                // selectionMode={SelectionMode.Partial}
                
                onSelectionDragStart={(event, element) => {
                    ConsoleUtil.log(`onSelectionStart`, event, element)
                }}

                onSelectionStart={(e) => {
                    ConsoleUtil.log(`onSelectionStart test`, e)
                }}

                onSelectionEnd={(e) => {
                    ConsoleUtil.log(`onSelectionEnd test`, e)
                }}

                style={{
                    height: "calc(100vh)",
                    width: "calc(100vh)"
                }}


                nodes={nodes}
                edges={edges}
                onNodeDragStop={updateGroupRelation}
                // nodes={initialNodes}
                // edges={initialEdges}
                // initialNodes
                // viewport={flowData.render.viewport}
                defaultViewport={flowData.render.viewport}
                onNodesChange={onNodesChangeGroup}
                onEdgesChange={onEdgesChangeGroup}
                // onInit={setRfInstance} // setRfInstance
                nodeTypes={FlowNodeTypeComponentMap}
            >
                {/* <div className="save__controls">
          <button onClick={onSave}>undo</button>
          <button onClick={onRestore}>redo</button>
          <button onClick={onAdd}>add node</button>
          <button onClick={onAdd}>add template</button> 
          <button onClick={onAdd}>add edge</button> 
          <button onClick={onAdd}>add memo</button> 
          <button onClick={onAdd}>copy</button>
          <button onClick={onAdd}>simple copy</button>
          <button onClick={onAdd}>paste</button>
          <button onClick={onAdd}>edit txt</button>
          <button onClick={onAdd}>edit grid</button>
          <button onClick={onAdd}>import</button>
          <button onClick={onAdd}>export</button>
          <button onClick={onAdd}>selection</button>
          <button onClick={onAdd}>all selection</button> 
        </div> */}
                <MiniMap
                    pannable={true}
                    zoomable={true}
                />
                <Background/>

                <Controls/>
            </ReactFlow>
        </>
    })

    return (
        <>
            {reactFlow(props)}
        </>
    );
}

export default (props) => (
    <ReactFlowProvider>
        <BaseFlow {...props} />
    </ReactFlowProvider>
);