import { atom, DefaultValue, RecoilValueReadOnly, selector } from 'recoil';
import produce from 'immer';
import ConfigInterface from '@interface/ConfigInterface';
import { DefaultConfig, DefaultDebugUIStyle, MODE_TYPE } from '@config/config';


export default class ConfigState {
  private static className: string = 'ConfigState'

  constructor() {
    if (this instanceof ConfigState) {
      throw Error('A static class cannot be instantiated.');
    }
  }

  static getKey = (key: string): string => {
    return `${ConfigState.className}/${key}`
  }

  static atom = atom<ConfigInterface>({
    key: ConfigState.getKey('atom'),
    default: {
      ...DefaultConfig
    },
  })

  static defaultDebugUIStyle: RecoilValueReadOnly<Object> = selector({
    key: ConfigState.getKey('defaultDebugUIStyle'),
    get: ({ get }) => { 
      const config = get(ConfigState.atom) 
      const debugUIStyle 
        = config.UI_MODE === MODE_TYPE.DEBUG 
        ? DefaultDebugUIStyle : {}
        
      return debugUIStyle
    },
  });

  static atomSelector: RecoilValueReadOnly<ConfigInterface> = selector({
    key: ConfigState.getKey('atomSelector'),
    get: ({ get }) => get(ConfigState.atom),
  });

  static update = selector({
    key: ConfigState.getKey('update'),
    get: ({ get }) => get(ConfigState.atom),
    set: ({ get, set }, newData) => {
      if (newData instanceof DefaultValue) return true;

      const orgData = get(ConfigState.atom)
      const newDataImmer = produce(orgData, draft => {
        draft = newData
      })

      return set(
        ConfigState.atom, newDataImmer
      )
    },
  });

}
