import api from '../api';
import {GrantType} from "@type/GrantType";
import jwt_decode from 'jwt-decode';
import {TokenInterface} from "@api/interface/tokenInterface";
import {TokenRequestInterface} from "@api/interface/tokenRequestInterface";

const signinOauth = async (authProviderName, authProviderCode) => {
    try {

        const tokenReq: TokenRequestInterface = {
            grantType: GrantType.AUTH_PROVIDER_CODE,
            authProviderName: authProviderName,
            authProviderCode: authProviderCode
        }

        const response = await api.post('/auth/token', tokenReq);

        const token: TokenInterface = response.data.data;

        localStorage.setItem('token', JSON.stringify(token));
        const decodedToken = jwt_decode(token.accessToken);
        localStorage.setItem('decodedToken', JSON.stringify(decodedToken));

        return true;
    } catch (error) {
        console.error(error);

        return false;
    }
};

export default signinOauth;
