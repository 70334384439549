export const DarkThemeMap = {

  SIDE: {
    border: "1px solid",
    bgcolor: (theme) =>
      theme.palette.mode === "dark" ? "grey.800" : "white",
    borderColor: (theme) =>
      theme.palette.mode === "dark" ? "grey.700" : "grey.300",
    color: 
      (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
  },

  TAB: {
    bgcolor: (isVisble) => isVisble ? 
    (theme) =>
      theme.palette.mode === "dark" ? "#101010" : "#fff"
      :
    (theme) =>
      theme.palette.mode === "dark" ? "#333" : "grey.300",

    borderColor: (theme) =>
      theme.palette.mode === "dark" ? "grey.700" : "grey.300",
    color: 
      (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
  },

  FOOTER: {
    bgcolor: (theme) =>
      theme.palette.mode === "dark"
        ? false ? "#101010" : "#333"
        : false ? "#fff" : "grey.300",
    color: (theme) =>
      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
  },


  BUTTON: {
    primary: (mode) => mode === "dark" ? "#FFF" : "rgba(0, 0, 0, 0.54)",
  }

};
