import BaseException from '../exception/BaseException';

export default class StringUtil {
    stringData?: string
    objectData: any = undefined

    setString = (data: string) => {
        this.stringData = data
        return this  
    }

    setObject = (data: any) => {
        this.objectData = data
        return this  
    }
    
    stringifyJSON = () => {
        this.stringData = JSON.stringify(this.objectData, null, 2)
        return this  
    }
    
    build = (): string => {
        if (this.stringData === undefined) {
            throw new BaseException(`result data undefined`)
        }
        return this.stringData
    }
}
  