import Table from '../page/workspace/commons/flowManager/nodeType/table/Table';
import Memo from '@nodeType/memo/Memo';
import Group from '@nodeType/group/Group';
import {FLOW_NODE_TYPE} from "@type/FlowNodeTypes";

export const FlowNodeTypeComponentMap = {
  [FLOW_NODE_TYPE.TABLE]: Table,
  [FLOW_NODE_TYPE.MEMO]: Memo,
  [FLOW_NODE_TYPE.GROUP]: Group,
};
