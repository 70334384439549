import * as React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ColorModeContext } from '@page/signup/header/ThemeToggleIconButton';
import SheetManager from "@page/signup/commons/sheetManager/SheetManager";
import Header from "@page/signup/header/Header";
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import signinOauth from "@api/sign/signinOauth";
import BaseException from "@exception/BaseException";
import { useNavigate  } from 'react-router-dom';

export default function Layout() {
    const navigate = useNavigate();


    const handleSigninOauth = async () => {

        const currentUri = window.location.href;
        const url = new URL(currentUri);
        const lastPart = url.pathname.split("/").filter(part => part !== "").pop();
        const authProviderName = lastPart?.toUpperCase();

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (!authProviderName) {
            throw new BaseException(`Invalid authProviderName > ${authProviderName}`)
        }

        if (!code) {
            throw new BaseException(`Invalid code > ${code}`)
        }

        const isSuccess = await signinOauth(authProviderName, code)

        if (!isSuccess) return navigate('/signin');

        return navigate('/');
    }

    handleSigninOauth()

    return (
        <div>
            test
        </div>
    );
}
