import { FLOW_NODE_TYPE } from "@type/FlowNodeTypes";
import FlowNodeState from "@recoil/FlowNodeState";
import * as React from "react";
import { useRecoilState } from 'recoil';

export default function Test() {

  const [flowMap, updateflowMap] = useRecoilState(FlowNodeState.update)

  const alertNum = () => {
    updateflowMap({
      flowMap: {
        id1 : {
          [Math.random()] : {
            type: FLOW_NODE_TYPE.GROUP,
            uuid: "string",
            name: "string",
            data: "any",
          }
        }
      }
    })
  }

  return (
    <div>
      <div onClick={alertNum}>
      test // 
      </div>
      <div>
        {JSON.stringify(flowMap, null, 2)}
      </div>
    </div>
  );
}
