import api from '../api';
import {UserInterface} from "@api/interface/userInterface";


const signup = async (username, password) => {
    try {
        const user: UserInterface = {
            email: username,
            pwd: password
        }

        const response = await api.post('/user/public/create', user);

        const token = response.data.token;

        return true;
    } catch (error) {
        console.error(error);

        return false;
    }
};

export default signup;
