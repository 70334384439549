import React, {useEffect} from 'react';
import ReactDataSheet from '@nodeType/table/react-datasheet/src/DataSheet';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-select/dist/react-select.css'
import 'react-datasheet/lib/react-datasheet.css';
import './Sheet.css';
import './ReSheet.css';
import {useRecoilState} from 'recoil';
import FlowNodeState from "@recoil/FlowNodeState";
import {FLOW_NODE_TYPE} from "@type/FlowNodeTypes"
import produce from 'immer';

interface SheetInterface {
    flowUuid: string
    flowNodeUuid: string
}

export default function Sheet(props: SheetInterface) {
    const [flowNode, updateflowNode] = useRecoilState(FlowNodeState.update)

    const flowUuid = props.flowUuid
    const flowNodeUuid = props.flowNodeUuid

    const DEFAULT_DATA = {
        title: "titlea",
        grid: [
            [
                {readOnly: true, value: 'Key'},
                {readOnly: true, value: 'Logical Name',},
                {readOnly: true, value: 'Physical Name',},
                {readOnly: true, value: 'Type',},
                {readOnly: true, value: 'Null',},
                {readOnly: true, value: 'Default',},
                {readOnly: true, value: 'Comment',},
            ],
            [
                {value: 'PK'},
                {value: 1},
                {value: 3},
                {value: 3},
                {value: 'NULL'},
                {value: ''},
                {value: 3},
            ],
            [
                {value: 'PK, FK(ddd.aaa)'},
                {value: 2},
                {value: 4},
                {value: 4},
                {value: 'NULL'},
                {value: ''},
                {value: 4},
            ],
            [
                {value: 'UK'},
                {value: 1},
                {value: 3},
                {value: 3},
                {value: 'NULL'},
                {value: ''},
                {value: 3},
            ],
            [
                {value: 'IDX(1)'},
                {value: 2},
                {value: 4},
                {value: 4},
                {value: 'NULL'},
                {value: ''},
                {value: 4},
            ],
            [
                {value: 'IDX(1)'},
                {value: 2},
                {value: 4},
                {value: 4},
                {value: 'NULL'},
                {value: ''},
                {value: 4},
            ],
            [
                {value: 'IDX(1)'},
                {value: 2},
                {value: 4},
                {value: 4},
                {value: 'NULL'},
                {value: ''},
                {value: 4},
            ],
            [
                {value: 'IDX(1)'},
                {value: 2},
                {value: 4},
                {value: 4},
                {value: 'NULL'},
                {value: ''},
                {value: 4},
            ],
            [
                {value: 'IDX(1)'},
                {value: 2},
                {value: 4},
                {value: 4},
                {value: 'NULL'},
                {value: ''},
                {value: 4},
            ],
            [
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
            ],
            [
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
            ],
            [
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
            ],
            [
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
            ],
            [
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
                {value: ''},
            ],
        ],
    };

    useEffect(() => {
        updateflowNode({
            flowMap: {
                [flowUuid]: {
                    [flowNodeUuid]: {
                        type: FLOW_NODE_TYPE.TABLE,
                        uuid: "string",
                        name: "string",
                        data: {
                            grid: DEFAULT_DATA.grid
                        },
                    }
                }
            }
        })
    }, [])


    //alert(JSON.stringify(nodeDataGroup.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"] ))
    const valueRenderer = cell => cell.value;
    const onCellsChanged = changes => {
        const grid = flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["grid"] ?? DEFAULT_DATA.grid
        const orgGrid = grid
        const newGrid = produce(orgGrid, draft => {
            console.log(JSON.stringify(changes))
            changes.forEach(({cell, row, col, value, state}) => {

                const start = state?.start;
                const end = state?.end;
                const selecting = state?.selecting;

                // draft[row][col]["value"] ??= {}
                // draft[row][col]["value"] = value
                if (!state) {
                    draft[row][col]["value"] ??= {}
                    draft[row][col]["value"] = value
                } else if (selecting == true) {
                    // edit selected cell
                    draft[row][col]["value"] ??= {}
                    draft[row][col]["value"] = value
                } else {
                    for (let i = start.i; i <= end.i; i++) {
                        for (let j = start.j; j <= end.j; j++) {
                            draft[i][j]["value"] ??= {}
                            draft[i][j]["value"] = value
                        }
                    }
                }

            })
        })

        updateflowNode({
            flowMap: {
                [flowUuid]: {
                    [flowNodeUuid]: {
                        type: FLOW_NODE_TYPE.TABLE,
                        uuid: "string",
                        name: "string",
                        data: {
                            grid: newGrid
                        },
                    }
                }
            }
        })

    };
    const onContextMenu = (e, cell, i, j) =>
        cell.readOnly ? e.preventDefault() : null;


    return (
        <>
            {/*<div>{flowUuid}</div>*/}
            {/*<div>{flowNodeUuid}</div>*/}
            {/*<div>{flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["title"] ?? DEFAULT_DATA.title}</div>*/}
            <ReactDataSheet
                className="nodrag"
                data={flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["grid"] ?? DEFAULT_DATA.grid}
                valueRenderer={valueRenderer}
                onContextMenu={onContextMenu}
                onCellsChanged={onCellsChanged}
            />
        </>
    );
}