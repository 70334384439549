import { atom, DefaultValue, RecoilValueReadOnly, selector } from 'recoil';
import produce from 'immer';
import FlowFunctionInterface from '@interface/FlowFunctionInterface';


export default class FlowFunctionState {
  private static className: string = 'FlowFunctionState'

  constructor() {
    if (this instanceof FlowFunctionState) {
      throw Error('A static class cannot be instantiated.');
    }
  }

  static getKey = (key: string): string => {
    return `${FlowFunctionState.className}/${key}`
  }

  static atom = atom<FlowFunctionInterface>({
    key: FlowFunctionState.getKey('NodeDataState'),
    default: {
      flowMap: {},
    },
  })

  static atomSelector: RecoilValueReadOnly<FlowFunctionInterface> = selector({
    key: FlowFunctionState.getKey('atomSelector'),
    get: ({ get }) => get(FlowFunctionState.atom),
  });

  static update = selector({
    key: FlowFunctionState.getKey('update'),
    get: ({ get }) => get(FlowFunctionState.atom),
    set: ({ get, set }, newData) => {
      if (newData instanceof DefaultValue) return true;

      const orgNodeData = get(FlowFunctionState.atom)
      const newDataImmer = produce(orgNodeData, draft => {
        Object.entries(newData.flowMap).forEach(
          ([newFlowUuid, newFlowNodeMap]) => {
            Object.entries(newFlowNodeMap)
              .forEach(([newFlowNodeUuid, newFlowFunction]) => {
                draft.flowMap[newFlowUuid] ??= {
                  addTableNode: () => undefined,
                  addMemoNode: () => undefined,
                  addGroupNode: () => undefined,
                  updateGroupRelation: () => undefined,
                }
                draft.flowMap[newFlowUuid][newFlowNodeUuid]
                  = newFlowFunction
              }
              )
          }
        )
      })

      return set(
        FlowFunctionState.atom, newDataImmer
      )
    },
  });


}
