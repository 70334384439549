// 👇 you need to import the reactflow styles
import 'reactflow/dist/style.css';
import React, { useRef } from 'react';
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import StringUtil from '@util/StringUtil';
import FlowInterface from '@interface/FlowInterface';
import { useSetRecoilState } from 'recoil';
import ProjectState from '@recoil/ProjectState';
import { ClassNames } from '@emotion/react';


interface BaseEditorInterface {
  flowData: FlowInterface
}

function BaseEditor(props: BaseEditorInterface) {
  const stringUtil = new StringUtil();
  const aceEditorRef = useRef<AceEditor>(null);

  const flowData = props.flowData

  const updateFlowData = useSetRecoilState(ProjectState.updateFlowDataList)
  // const flowMap = useRecoilValue(ProjectInfo.flowMap)
  const prettierString = stringUtil
    .setObject(flowData)
    .stringifyJSON()
    .build()

  // const [aceEditorValue, setAceEditorValue] = 
  //   useState<string>(stringUtil
  //     .setObject(flowData)
  //     .stringifyJSON()
  //     .build()
  // )

  const onChangeAceEditor = (editorText: string) => {
    if (flowData === undefined) {
      return
    }

    let newFlowData: FlowInterface
    try {
      newFlowData = JSON.parse(editorText)
      updateFlowData([newFlowData])
    } catch (error) {
      console.error(error);
    }
  }

  // useEffect(() => {
  //   let aceEditorValueObj = ``
  //   try {
  //     const targetIdx = props.projectInfo.flowDataList.findIndex((e: any) => e.uuid === props.uuid)
  //     if (targetIdx === undefined) {
  //       return
  //     }

  //     aceEditorValueObj = JSON.parse(aceEditorValue)
  //     props.projectInfo.flowDataList[targetIdx] = aceEditorValueObj
  //     setFlowData(aceEditorValueObj)
  //   } catch (error) {
  //     console.error(error);
  //   } 

  //   // props?.setProjectInfo?.(Object.assign({}, props.projectInfo))
  // }
  // , [aceEditorValue, props, setFlowData]);

  // useEffect(() => {
  //   const stringUtil  = new StringUtil();
  //   setAceEditorValue(stringUtil
  //     .setObject(flowData)
  //     .stringifyJSON()
  //     .build())
  // }
  // , [flowData]);

  let isVisible = flowData.isVisible

  if (!isVisible) {
    return <></>
  }

  return (
      <AceEditor
        mode="json"
        theme="monokai"
        name="inputBody"
        highlightActiveLine
        setOptions={{
          showLineNumbers: true,
          tabSize: 2,
          useWorker: false,
          fontSize: "15pt",
        }}
        style={{
          height: "calc(100%)",
          width: "calc(100%)"
        }}
        ref={aceEditorRef}
        value={prettierString}
        onChange={(newValue) => onChangeAceEditor(newValue)}

        wrapEnabled
        showPrintMargin={false}
      />
  );
}

export default BaseEditor;
