import ConfigInterface from "@interface/ConfigInterface";

const BASE_URL = 'http://0000.0000.00000.0000:000000';

export const API = {
  MAIN: `${BASE_URL}/main`,
  MYPAGE: `${BASE_URL}/users/private/user`,
  USER_PAGE: `${BASE_URL}/users/public/user`,
  SIGNUP: `${BASE_URL}/users/signup`,
  WRITERDATA: `${BASE_URL}/users/?user_tag_id=`,
  TAGDATA: `${BASE_URL}/branch_tags/userTagList`,
  DITAILLIST: `${BASE_URL}/postings`,
  KEYWORDS: `${BASE_URL}/keywords/list`,
  LOGIN: `${BASE_URL}/users/signin`,
  DETAIL_PAGE: `${BASE_URL}/postings/detail`,
  RELATED: `${BASE_URL}/postings`,
};

export const DefaultDebugUIStyle = {
  outline: "2px solid"
};

export const enum MODE_TYPE {
  DEBUG = "DEBUG",
  DEV = "DEV",
  PROD = "PROD",
}

export const enum LOG_LEVEL_TYPE {
  TRACE = "TRACE",
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  FATAL = "FATAL",
}

export const DefaultConfig: ConfigInterface = {
  UI_MODE: MODE_TYPE.PROD,
  RUNTIME_MODE: MODE_TYPE.DEBUG,
  DEBUG_MODAL_MODE: MODE_TYPE.DEBUG,
  LOG_LEVEL: [
    LOG_LEVEL_TYPE.TRACE,
    LOG_LEVEL_TYPE.DEBUG,
    LOG_LEVEL_TYPE.INFO,
    LOG_LEVEL_TYPE.WARN,
    LOG_LEVEL_TYPE.ERROR,
    LOG_LEVEL_TYPE.FATAL,
  ],
};