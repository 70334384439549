import "./Layout.css";
import * as React from "react";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HeightMap } from "@map/HeightMap";
import { useRecoilValue } from "recoil";
import { DarkThemeMap } from "@map/DarkThemeMap";
import ConfigState from "@recoil/ConfigState";
import Main from ".//main/Main";
import { ColorModeContext } from './/header/ThemeToggleIconButton';
import SheetManager from ".//commons/sheetManager/SheetManager";
import Header from "./header/Header";
import jwt_decode from 'jwt-decode';
import axios from 'axios';

export default function Layout() {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)
  const DEFAULT_THEME = 'dark'
  const [mode, setMode] = React.useState<'light' | 'dark'>(DEFAULT_THEME);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
    const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: DarkThemeMap.BUTTON.primary(mode), 
          }
        },
      }),
    [mode],
  );


//     // TODO :: 회원 관련 기능 구현
//     // JWT 디코딩
//     const token = 'YOUR_TOKEN_HERE';
//     const decodedToken = jwt_decode(token);
//     // 로그인 후, 토큰 저장
//     localStorage.setItem('token', token);
//
//     // 로그아웃 후, 토큰 삭제
//     localStorage.removeItem('token');
//
//     const test = () => {
//         // JWT 전송
//         const token = localStorage.getItem('token');
//         const options = {
//             headers: { Authorization: `Bearer ${token}` }
//         };
//
//         axios.get('/api/data', options).then((response) => {
//             console.log(response.data);
//         });
//     }
//
//     const testInterceptor = () => {
//         const instance = axios.create({
//             baseURL: 'http://localhost:8080' // 서버의 주소
//         });
//
// // Request interceptor
//         instance.interceptors.request.use(
//             (config) => {
//                 const token = localStorage.getItem('token'); // 로컬 스토리지에서 토큰 가져오기
//                 if (token) {
//                     // @ts-ignore
//                     config.headers.Authorization = `Bearer ${token}`; // 헤더에 토큰 추가
//                 }
//                 return config;
//             },
//             (error) => Promise.reject(error)
//         );
//     }

    return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            overflowY: "scroll",
            height: "100vh",
            maxHeight: "100vh",
            width: "100vw"
          }}
        >
          <Header></Header>
          <Box
            sx={{
              display: "flex",
              borderRadius: 1,
              height: `calc(100vh - ${HeightMap.HEADER_HEIGHT})`,
  
              ...defaultDebugUIStyle,
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: 1,
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "stretch",

                ...defaultDebugUIStyle,
              }}
            >
              <Main />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
