import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ProjectState from '@recoil/ProjectState';
import Tab from './Tab';
import ItemBaseTab from './BaseTab';
import AddIcon from '@mui/icons-material/Add';
import produce from 'immer';
import { DarkThemeMap } from '@map/DarkThemeMap';
import ConfigState from '@recoil/ConfigState';
import { HeightMap } from '@map/HeightMap';
import { Scrollbar } from 'react-scrollbars-custom';
import FlowInterface from '@interface/FlowInterface';

interface TabManagerInterface {

}

function TabManager(props: TabManagerInterface) {
  const projectInfo = useRecoilValue(ProjectState.projectInfo)
  const disableAllViewFlowDataList = useSetRecoilState(ProjectState.disableAllViewFlowDataList)
  const updateFlowData = useSetRecoilState(ProjectState.updateFlowDataList)
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)

  const scrollRef = useRef<null | HTMLDivElement>(null)
  const scrollEndRef = useRef<null | HTMLDivElement>(null)

  const executeScroll = () => {

    if (scrollRef) {
      scrollRef.current?.scrollIntoView({behavior : 'smooth'})
    }

    const flowDataList = Object.values(projectInfo.flowMap)
    const isVisible = flowDataList[flowDataList.length - 1].isVisible
    if(!isVisible) return

    if (scrollEndRef) {
      scrollEndRef.current?.scrollIntoView({behavior : 'smooth'})
    }

  }

  useEffect(() => {
    executeScroll()
  }, [projectInfo]);



  const tabs = () => {
    return Object.values(projectInfo.flowMap)
      // .getSortedFlowDataListByRgst()
      .map((e: FlowInterface, idx: number) =>
        <>
          <Tab
            key={idx}
            uuid={e.uuid}
            flowData={e}
          ></Tab> 
          {e.isVisible ? <span ref={scrollRef}></span> : null}
        </>
      )
  }

  const createTab = () => {
    

    const flowDataList = Object.values(projectInfo.flowMap)
    const newFlowDataList = flowDataList.map(e => {
      const newFlowData = produce(e, draft => {
        draft.isVisible = false

        return draft
      })
      return newFlowData
    })

    updateFlowData([...newFlowDataList, ProjectState.getInitFlowData()])

    // createFlowData([ProjectInfo.getInitFlowData()])
  }

  const disableAllView = () => {
    disableAllViewFlowDataList()
  }

  return (
    <>

      <Box
        sx={{
          whiteSpace: "nowrap",
          height: HeightMap.TAB_HEIGHT,
          bgcolor: DarkThemeMap.SIDE.bgcolor,
          // borderBottom: DarkThemeMap.SIDE.border,
          borderBottomColor: DarkThemeMap.SIDE.borderColor,

          ...defaultDebugUIStyle,
        }}
      >

        <Scrollbar noScrollY>

          {tabs()}
          <Tooltip title="AddIcon">
            <IconButton onClick={createTab} >
              <AddIcon />
            </IconButton>
          </Tooltip>
          {/* <ItemBaseTab>
        <Tooltip title="VisibilityOffIcon">
          <IconButton onClick={disableAllView} color="inherit">
            <VisibilityOffIcon />
          </IconButton>
        </Tooltip>
      </ItemBaseTab> */}
          <span ref={scrollEndRef}></span>

        </Scrollbar>
      </Box >

    </>
  );
}

export default TabManager;
