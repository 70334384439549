import React, {memo, FC, useRef, useEffect, useCallback} from 'react';
import {Handle, Position, NodeProps} from 'reactflow';

import {NodeResizer} from '@reactflow/node-resizer';

import './resize.css';
import './nodetype.css';
import {useRecoilState, useRecoilValue} from "recoil";
import FlowFunctionState from "@recoil/FlowFunctionState";
import ProjectState from "@recoil/ProjectState";
import FlowNodeState from "@recoil/FlowNodeState";
import {FLOW_NODE_TYPE} from "@type/FlowNodeTypes";
import {Node} from "@reactflow/core/dist/esm/types/nodes";
import ConsoleUtil from "@util/ConsoleUtil";
import produce from "immer";
import FlowInterface from "@interface/FlowInterface";
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import CloseIcon from "@mui/icons-material/Close";
import TableViewIcon from "@mui/icons-material/TableView";
import EditIcon from "@mui/icons-material/Edit";


interface GroupInterface extends NodeProps {
    id: string,
    data: {
        label: string,
        flowUuid: string,
        flowNodeUuid: string,
    },
    selected: boolean
}

const Group = (props: GroupInterface) => {
    const NODE_MIN_HEIGHT = 300;
    const NODE_MIN_WIDTH = 300;
    const HEADER_HEIGHT = 45;
    const {data, selected} = props;
    const label = data?.label ?? 'Group';

    const nodeRef = useRef<HTMLDivElement>(null);
    const projectState = useRecoilValue(ProjectState.projectInfo)
    const selectedFlow = Object.values(projectState.flowMap).filter(e => e.isVisible === true).pop()
    const selectedUuid = selectedFlow?.uuid
    const flowFunctionState = useRecoilValue(FlowFunctionState.atom)
    const [flowNode, updateflowNode] = useRecoilState(FlowNodeState.update)
    const include = flowNode.flowMap?.[props.data.flowUuid]?.[props.data.flowNodeUuid]?.["data"]?.["include"] ?? ""

    const updateGroupRelation = useCallback(() => {
        // ConsoleUtil.log(`updateGroupRelation`)
        const nodesToUpdate: Partial<Node>[] = []

        if (!selectedUuid) return
        if (selectedFlow == null) return
        const nodes = selectedFlow.render.nodes

        // ConsoleUtil.log(`nodesInside`, nodes)
        nodes.forEach((node) => {
            const nodesInside = getNodesInside(node, nodes)
            // ConsoleUtil.log(`nodesInside`, node, nodesInside)

            if (node.type !== FLOW_NODE_TYPE.GROUP) return
            const newNode = {
                id: node.id,
                data: {
                    include: {
                        idMap: {}
                    }
                }
            }

            nodesInside.forEach((subject: Node) => {
                newNode.data.include.idMap[subject.id] = {data: subject.data.flowNodeUuid};
            })
            nodesToUpdate.push(newNode)
        })

        nodesToUpdate.forEach((nodePartialToUpdate: Partial<Node>) => {
            if (props.id !== nodePartialToUpdate.id) return
            if (nodePartialToUpdate.id == undefined) return;

            // console.log(`nodePartialToUpdate.data`)
            // console.log(`nodePartialToUpdate.data`, nodePartialToUpdate)
            // console.log(`props.data.flowNodeUuid.data`, props.data.flowNodeUuid)
            updateflowNode({
                flowMap: {
                    [selectedUuid]: {
                        [props.data.flowNodeUuid]: {
                            type: FLOW_NODE_TYPE.GROUP,
                            uuid: "string",
                            name: "string",
                            data: {
                                ...props.data,
                                include: nodePartialToUpdate.data.include
                            },
                        }
                    }
                }
            })
        })

    }, [selectedUuid, selectedFlow, props.data.flowNodeUuid]);
    const getNodesInside = useCallback((object, subjectArray: Array<any>): Array<any> => {
        if (object.width == null) return [];
        if (object.height == null) return [];

        const startX = object.position.x;
        const endX = object.position.x + object.width;
        const startY = object.position.y;
        const endY = object.position.y + object.height;

        // ConsoleUtil.log(`startX, endX`, startX, endX)
        // ConsoleUtil.log(`startY, endY`, startY, endY)

        const nodesInside = subjectArray.filter((subject: Node) => {
            // ConsoleUtil.log(`n.id`, subject.id)
            // ConsoleUtil.log(`n.id`, subject)
            // ConsoleUtil.log(`n.position.x`, subject.position.x)
            // ConsoleUtil.log(`n.position.y`, subject.position.y)

            if (subject.width == null) return;
            if (subject.height == null) return;

            const startTargetX = subject.position.x;
            const endTargetX = subject.position.x + subject.width;
            const startTargetY = subject.position.y;
            const endTargetY = subject.position.y + subject.height;

            return (
                startX < startTargetX &&
                endX > startTargetX &&
                startX < endTargetX &&
                endX > endTargetX &&
                startY < startTargetY &&
                endY > startTargetY &&
                startY < endTargetY &&
                endY > endTargetY &&
                subject.id !== object.id
            )
        });

        return nodesInside
    }, [updateGroupRelation])


    const nodeHeight = nodeRef.current?.clientHeight;
    const nodeWidth = nodeRef.current?.clientWidth;

    useEffect(() => {
        updateGroupRelation()
    }, [selectedFlow])

    const nodeStyle = {
        backgroundColor: "rgba(240, 240, 240, 0.25)",
        minHeight: `${NODE_MIN_HEIGHT}px`,
        minWidth: `${NODE_MIN_WIDTH}px`,
        height: `100%`,
        width: `100%`,
        // pointerEvents: "none",
        // zIndex: props.selected ? -10000 : -10000,
    }

    const headerStyle = {
        height: `${HEADER_HEIGHT}px`,
        borderBottom: `1px solid #e0e0e0`,
    }

    return (
        <div
            ref={nodeRef}
            className="nodetype-group"
            style={{...nodeStyle}}
            // onClick={(e) => {e.preventDefault(); return false}}
        >
            <div style={{...headerStyle}}>
                <Box
                    sx={{display: 'flex', borderRadius: 1}}
                >
                    <Box>
                        <IconButton>
                            <TableViewIcon style={{fontSize: '1.5rem'}}/>
                        </IconButton>
                        <IconButton>
                            <EditIcon style={{fontSize: '1.5rem'}}/>
                        </IconButton>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                    </Box>
                    <Box>
                        <IconButton>
                            <CloseIcon style={{fontSize: '1.5rem'}}/>
                        </IconButton>
                    </Box>
                </Box>
            </div>

            {label} - {JSON.stringify(include)}

            <NodeResizer
                isVisible={selected}
                minWidth={NODE_MIN_WIDTH}
                minHeight={NODE_MIN_HEIGHT}
                // onResizeEnd={updateGroupRelation}
            />
            {/*<Handle type="target" position={Position.Left} />*/}
            {/*<Handle type="source" position={Position.Right} />*/}
        </div>
    );
};

export default memo(Group);
