// 👇 you need to import the reactflow styles
import 'reactflow/dist/style.css';
import React, { useCallback, useEffect, useState } from 'react';
// import Grid from '@mui/material/Grid'; // Grid version 1import Grid from '@mui/material/Grid'; // Grid version 1
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Button, IconButton } from '@mui/material';
import ProjectState from '@recoil/ProjectState';
import FlowInterface from '@interface/FlowInterface';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import produce from 'immer';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import RawOnIcon from '@mui/icons-material/RawOn';
import RawOffIcon from '@mui/icons-material/RawOff';
import ItemBaseTab from './BaseTab';

interface TabInterface {
  uuid: string
  flowData: FlowInterface
}

function Tab(props: TabInterface) {
  const projectState = useRecoilValue(ProjectState.projectInfo)
  // const flowData: FlowDataInterface = projectInfoData.flowMap[props.uuid]

  const removeFlowData = useSetRecoilState(ProjectState.deleteFlowDataList)
  const updateFlowData = useSetRecoilState(ProjectState.updateFlowDataList)
  const flowData = props.flowData
  // const flowData = flowDataList.filter((e: FlowDataInterface) => e.uuid === props.uuid).pop()
  // const [flowData, setFlowData] = useState<FlowDataInterface>(projectInfo.data.flowMap.get(props.uuid))

  // UPLOAD
  // HISTORY, UNDO, REDO
  // COMMIT
  // DEPLOY

  // const [, updateState] = useState<any>(); // 상태 변수는 선언하지 않는다
  // const forceUpdate = useCallback(() => updateState({}), []);

  // useEffect(() => {
  //   // props?.setProjectInfo?.(Object.assign({}, projectInfo))
  //   forceUpdate()
  // }
  // , [props.flowData, forceUpdate]);

  const removeTab = (uuid: string) => {
    if (flowData === undefined) {
      return
    }

    removeFlowData([flowData])
  }

  const toggleVisbileTab = (uuid: string) => {
    if (flowData === undefined) {
      return
    }

    console.log(`toggleVisbileTab`)
    console.log(JSON.stringify(flowData))

    // const newFlowData = produce(flowData, draft => {
    //   draft.isVisible = !draft.isVisible
    // })
    // console.log(JSON.stringify(newFlowData))
    // updateFlowData([newFlowData])

    const flowDataList = Object.values(projectState.flowMap)
    const newFlowDataList = flowDataList.map(e => {
      const newFlowData = produce(e, draft => {
        draft.isVisible = false

        if (draft.uuid == flowData.uuid) {
          draft.isVisible = true
        }

        return draft
      })
      return newFlowData
    })

    updateFlowData(newFlowDataList)

  }

  const toggleEditTab = (uuid: string) => {
    if (flowData === undefined) {
      return
    }

    // const newFlowData = produce(flowData, draft => {
    //   draft.isEdit = !draft.isEdit
    // })

    // updateFlowData([newFlowData])

    const flowDataList = Object.values(projectState.flowMap)
    const newFlowDataList = flowDataList.map(e => {
      const newFlowData = produce(e, draft => {
        draft.isVisible = false

        if (draft.uuid === flowData.uuid) {
          draft.isEdit = !draft.isEdit
          draft.isVisible = true
        }

        return draft
      })
      return newFlowData
    })

    updateFlowData(newFlowDataList)
  }

  return (
    <ItemBaseTab
      isVisible={flowData.isVisible}
      onClick={() => {
        toggleVisbileTab(props.uuid)
      }}
    >
      
      <IconButton
        onClick={(e) => {e.stopPropagation(); toggleEditTab(props.uuid); }}
        color="inherit">
        {!flowData.isEdit ? <RawOnIcon /> : <RawOffIcon />}
      </IconButton>
      <>{flowData.name}</>
      {/* <>isVisible : {JSON.stringify(flowData.isVisible)}</>
        <>isEdit : {JSON.stringify(flowData.isEdit)}</> */}


      {/* <IconButton
        onClick={(e) => {e.stopPropagation(); toggleVisbileTab(props.uuid); }}
        color="inherit">
        {flowData.isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton> */}



      <IconButton
        onClick={(e) => {e.stopPropagation(); removeTab(props.uuid); }}
        color="inherit">
        <CloseIcon />
      </IconButton>
    </ItemBaseTab >
  );
}

export default Tab;
