// import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { DarkThemeMap } from "@map/DarkThemeMap";
import { HeightMap } from "@map/HeightMap";

export default function BaseTab(props: any) {
  const { sx, ...other } = props;

  return (
    <Button
      sx={{
        bgcolor: DarkThemeMap.TAB.bgcolor(props.isVisible),
        color: DarkThemeMap.TAB.color,
        borderColor: DarkThemeMap.TAB.borderColor,
        paddingTop: "0px",
        paddingBottom: "0px",
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx
      }}
      {...other}
    />
  );
}

// TestBaseTab.propTypes = {
//   /**
//    * The system prop that allows defining system overrides as well as additional CSS styles.
//    */
//   isVisible: PropTypes.bool,
//   sx: PropTypes.oneOfType([
//     PropTypes.arrayOf(
//       PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
//     ),
//     PropTypes.func,
//     PropTypes.object
//   ])
// };
