import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ConfigState from "@recoil/ConfigState";
import { useRecoilValue } from "recoil";
import { DarkThemeMap } from "@map/DarkThemeMap";
import { WidthMap } from "@map/WidthMap";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import HistoryIcon from '@mui/icons-material/History';
import ArchiveIcon from '@mui/icons-material/Archive';
import GitHubIcon from '@mui/icons-material/GitHub';
import CommitIcon from '@mui/icons-material/Commit';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Scrollbar from "react-scrollbars-custom";
import { HeightMap } from "@map/HeightMap";

function Right(props: any) {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)

  const createTab = () => {
    alert(1)
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          bgcolor: DarkThemeMap.SIDE.bgcolor,
          borderLeft: DarkThemeMap.SIDE.border,
          borderLeftColor: DarkThemeMap.SIDE.borderColor,

          ...defaultDebugUIStyle,
        }}
      >
        <Box
          sx={{
            height: `calc((100vh - ${HeightMap.HEADER_HEIGHT}) / 2 - 1px)`,
            display: "flex",
            flexDirection: "column",
            maxWidth: WidthMap.RIGHT_MAX_WIDTH,
            minWidth: WidthMap.RIGHT_MIN_WIDTH,

            ...defaultDebugUIStyle,
          }}
        >

          <Scrollbar noScrollX>
            <Tooltip title="Undo" placement="left">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <UndoIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Redo" placement="left">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <RedoIcon />
              </Button>
            </Tooltip>

            <Tooltip title="History" placement="left">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <HistoryIcon />
              </Button>
            </Tooltip>

            <Tooltip title="Snapshot" placement="left">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <ArchiveIcon />
              </Button>
            </Tooltip>

          </Scrollbar>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            maxWidth: WidthMap.RIGHT_MAX_WIDTH,
            minWidth: WidthMap.RIGHT_MIN_WIDTH,

            ...defaultDebugUIStyle,
          }}
        >

          <Scrollbar noScrollX>
            <Box
              sx={{
                minHeight: `calc((100vh - ${HeightMap.HEADER_HEIGHT}) / 2 - 5px)`,
                display: "flex",
                flexDirection: "column-reverse",
                maxWidth: WidthMap.RIGHT_MAX_WIDTH,
                minWidth: WidthMap.RIGHT_MIN_WIDTH,

                ...defaultDebugUIStyle,
              }}
            >

              <Tooltip title="Git Config" placement="left">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <GitHubIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Commit" placement="left">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <CommitIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Deploy" placement="left">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <PlayCircleFilledWhiteIcon />
                </Button>
              </Tooltip>

              {/* <Tooltip title="Save (Electron)" placement="left">
            <Button fullWidth sx={{ minWidth: 0 }}  onClick={createTab}>
              <AddIcon />
            </Button>
          </Tooltip>

          <Tooltip title="Save (Tmp)" placement="left">
            <Button fullWidth sx={{ minWidth: 0 }}  onClick={createTab}>
              <AddIcon />
            </Button>
          </Tooltip> */}

            </Box>

          </Scrollbar>

        </Box>
      </Box>
    </>
  );
}

export default Right;
