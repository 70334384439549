import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Workspace from '@page/workspace/Layout';
import Explore from '@page/explore/Layout';
import SignIn from '@page/signin/Layout';
import SignUp from '@page/signup/Layout';
import Home from '@page/home/Layout';
import Oauth from '@page/oauth/Layout';
import Test from '@page/test/Test';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <>
              <Workspace />
            </>
          }></Route>
          <Route path="/home" element={
            <>
              <Home />
            </>
          }></Route>
          <Route path="/workspace" element={
            <>
              <Workspace />
            </>
          }></Route>
          <Route path="/explore" element={
            <>
              <Explore />
            </>
          }></Route>
          <Route path="/signin" element={
            <>
              <SignIn />
            </>
          }></Route>
          <Route path="/signup" element={
            <>
              <SignUp />
            </>
          }></Route>
          <Route path="/callback/oauth2/code/*" element={
              <Oauth />
          }></Route>

          <Route path="/test" element={<Test />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
