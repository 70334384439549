import * as React from "react";
import Box from "@mui/material/Box";
import ConfigState from "@recoil/ConfigState";
import { useRecoilValue } from "recoil";
import { WidthMap } from "@map/WidthMap";
import { DarkThemeMap } from "@map/DarkThemeMap";
import ProjectState from "@recoil/ProjectState";
import FlowFunctionState from "@recoil/FlowFunctionState";
import { HeightMap } from "@map/HeightMap";

function Left(props: any) {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)
  const projectState = useRecoilValue(ProjectState.projectInfo)
  const flowFunctionState = useRecoilValue(FlowFunctionState.atom)

  const selectedFlow = Object.values(projectState.flowMap).filter(e => e.isVisible === true).pop()
  const selectedUuid = selectedFlow?.uuid

  const addTableNode = () => {
    if (!selectedUuid) {
      return
    }

    flowFunctionState.flowMap[selectedUuid].addTableNode()
  }

  const addMemoNode = () => {
    if (!selectedUuid) {
      return
    }

    flowFunctionState.flowMap[selectedUuid].addMemoNode()
  }

  const addGroupNode = () => {
    if (!selectedUuid) {
      return
    }

    flowFunctionState.flowMap[selectedUuid].addGroupNode()
  }

  const createTab = () => {
    alert(1)
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          bgcolor: DarkThemeMap.SIDE.bgcolor,
          borderRight: DarkThemeMap.SIDE.border,
          borderRightColor: DarkThemeMap.SIDE.borderColor,

          ...defaultDebugUIStyle,
        }}
      >

        <Box
          sx={{
            height: `calc((100vh - ${HeightMap.HEADER_HEIGHT}) / 2 - 1px)`,
            display: "flex",
            flexDirection: "column",
            maxWidth: WidthMap.LEFT_MAX_WIDTH,
            minWidth: WidthMap.LEFT_MIN_WIDTH,

            ...defaultDebugUIStyle,
          }}
        >

        </Box>


        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            maxWidth: WidthMap.LEFT_MAX_WIDTH,
            minWidth: WidthMap.LEFT_MIN_WIDTH,

            ...defaultDebugUIStyle,
          }}

        >


        </Box>

      </Box >
    </>
  );
}

export default Left;
