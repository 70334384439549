import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FlowManager from "../commons/flowManager/FlowManager";
import TabManager from "@workspace/commons/tabManager/TabManager";
import { HeightMap } from "@map/HeightMap";
import ConfigState from "@recoil/ConfigState";
import { useRecoilValue } from "recoil";

function Main() {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)

  const HEADER_HEIGHT: string = HeightMap.HEADER_HEIGHT
  const FOOTER_HEIGHT: string = HeightMap.FOOTER_HEIGHT

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          borderRadius: 1,
          overflowX: "scroll",
          alignItems: "stretch",
          flexWrap: "wrap",

          ...defaultDebugUIStyle,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                borderRadius: 1,
                flexDirection: "column",
                flexGrow: 1,
                height: `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`,
                alignItems: "stretch",

                ...defaultDebugUIStyle,
              }}
            >
              <TabManager />
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  borderRadius: 1,
                  overflowY: "scroll",
                  alignItems: "stretch",
                  flexWrap: "wrap",
                  alignContent: "stretch",
                  flexDirection: "row",

                  ...defaultDebugUIStyle,
                }}
              >
                <Box sx={{
                  flexGrow: 1,

                  ...defaultDebugUIStyle,
                }}>
                  <FlowManager />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Main;
