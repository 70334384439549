import { atom, DefaultValue, RecoilValueReadOnly, selector } from 'recoil';
import produce from 'immer';
import FlowNodeInterface from '@interface/FlowNodeInterface';


export default class FlowNodeState {
  private static className: string = 'FlowNodeState'

  constructor() {
    if (this instanceof FlowNodeState) {
      throw Error('A static class cannot be instantiated.');
    }
  }

  static getKey = (key: string): string => {
    return `${FlowNodeState.className}/${key}`
  }

  static atom = atom<FlowNodeInterface>({
    key: FlowNodeState.getKey('NodeDataState'),
    default: {
      flowMap: {},
    },
  })

  static atomSelector: RecoilValueReadOnly<FlowNodeInterface> = selector({
    key: FlowNodeState.getKey('atomSelector'),
    get: ({ get }) => get(FlowNodeState.atom),
  });

  static update = selector({
    key: FlowNodeState.getKey('update'),
    get: ({ get }) => get(FlowNodeState.atom),
    set: ({ get, set }, newData) => {
      if (newData instanceof DefaultValue) return true;

      const orgNodeData = get(FlowNodeState.atom)
      const newDataImmer = produce(orgNodeData, draft => {
        Object.entries(newData.flowMap).forEach(
          ([newFlowUuid, newFlowNodeMap]) => {
            Object.entries(newFlowNodeMap)
              .forEach(([newFlowNodeUuid, newFlowNode]) => {
                draft.flowMap[newFlowUuid] ??= {}
                draft.flowMap[newFlowUuid][newFlowNodeUuid]
                  = newFlowNode
              }
              )
          }
        )
      })

      return set(
        FlowNodeState.atom, newDataImmer
      )
    },
  });


}
