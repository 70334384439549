import 'reactflow/dist/style.css';
import React from 'react';
import BaseFlow from './BaseFlow';
import BaseEditor from './BaseEditor';
import ProjectState from '@recoil/ProjectState';
import FlowInterface from '@interface/FlowInterface';
import { useRecoilValue } from 'recoil';

interface FlowManagerInterface {
}

function FlowManager(props: FlowManagerInterface) {
  const flowDataList = useRecoilValue(ProjectState.flowDataList)

  const flows = (props: any) => {
    // const filteredVisible = flowDataList
    // .filter((e: FlowDataInterface) => e.isVisible)

    const getRenderer = (e: FlowInterface, idx: number) => {
      if (e.isEdit === false) {
        return (
          <BaseFlow
            key={idx}
            uuid={e.uuid}
            flowData={e}
          >
          </BaseFlow>
        )
      } else {
        return (
          <BaseEditor
            key={idx}
            flowData={e}
          />
        )
      }
    }

    // return filteredVisible
    return flowDataList
      .map((e, idx: number) =>
        getRenderer(e, idx)
      )
  }

  return (
    <>
      {flows(props)}
    </>
  );
}

export default FlowManager;
