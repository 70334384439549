interface DateMap {
  yyyy: string
  MM: string
  dd: string
  HH: string
  mm: string
  ss: string
}

export default class DateUtil {

    static getDateMap = (): DateMap => {
      let date = new Date()

      let yyyy = String(date.getFullYear())
      let MM = String(date.getMonth() + 1).padStart(2, '0')
      let dd = String(date.getDate()).padStart(2, '0')
      let HH = String(date.getHours()).padStart(2, '0')
      let mm = String(date.getMinutes()).padStart(2, '0')
      let ss = String(date.getSeconds()).padStart(2, '0')

      let DateMap = {
        yyyy: yyyy,
        MM: MM,
        dd: dd,
        HH: HH,
        mm: mm,
        ss: ss,
      }
      
      return DateMap
    }
      
    static getCurrentDate = (): string => {
      const d = this.getDateMap()

      return `${d.yyyy}${d.MM}${d.dd}${d.HH}`
    }

    static getCurrentDateTime = (): string => {
      const d = this.getDateMap()

      return `${d.yyyy}${d.MM}${d.dd}${d.HH}${d.mm}${d.ss}`
    }

    static getCurrentUnixTime = (): number => {
      return Date.now()
    }

}
  