import React, {memo, FC, useState, useRef, useEffect} from 'react';
import {Handle, Position, NodeProps} from 'reactflow';

import {NodeResizer} from '@reactflow/node-resizer';

import TextField from '@mui/material/TextField';
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import {Button, ButtonGroup, Grid, IconButton} from "@mui/material";
import CssUtil from "@util/CssUtil";
import './resize.css';
import './nodetype.css';
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

interface MemoInterface extends NodeProps {
    data: {
        label: string
    },
    selected: boolean
}

const dragHandleStyle = {};


const Memo = (memoProps: MemoInterface) => {
    const NODE_MIN_HEIGHT = 300;
    const NODE_MIN_WIDTH = 300;
    const HEADER_HEIGHT = 45;
    const EDITOR_PADDING = 17 * 2;
    const FONT_SIZE = 23;
    const TEXT_FIELD_FONT_SIZE_REM = 1;
    const INIT_ROWS = 9;
    const {data, selected} = memoProps;
    const label = data?.label ?? 'Memo';

    const [rows, setRows] = useState(INIT_ROWS);
    const [value, setValue] = useState('');
    const nodeRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        handleSetRow(undefined);
    })

    const handleSetRow = (e) => {
        const nodeHeightRef = nodeRef.current?.clientHeight;
        const inputHeightRef = inputRef.current?.clientHeight;
        if (inputHeightRef === undefined || nodeHeightRef === undefined) {
            return;
        }
        const CALC_ROWS = Math.floor((nodeHeightRef - (HEADER_HEIGHT + EDITOR_PADDING)) / FONT_SIZE);

        return setRows(CALC_ROWS);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            setValue(value + '\t');
        }
        if (e.shiftKey === true) {
            return false;
        }
    };

    const nodeStyle = {
        backgroundColor: "rgba(240, 240, 240, 0.25)",
        height: "100%",
        minHeight: `${NODE_MIN_WIDTH}px`,
        minWidth: `${NODE_MIN_WIDTH}px`
    }

    return (
        <div
            ref={nodeRef}
            style={{...nodeStyle}}
            className="nodetype-memo"
        >
            <div style={{height: `${HEADER_HEIGHT}px`}}>
                <Box
                    sx={{display: 'flex', borderRadius: 1}}
                >
                    <Box>
                        <IconButton>
                            <NoteAltIcon style={{fontSize: '1.5rem'}}/>
                        </IconButton>
                    </Box>
                    <Box sx={{flexGrow: 1}}>
                    </Box>
                    <Box>
                        <IconButton>
                            <CloseIcon style={{fontSize: '1.5rem'}}/>
                        </IconButton>
                    </Box>
                </Box>
            </div>

            <TextField
                ref={inputRef}
                className="nodrag"
                inputProps={{style: {fontSize: `${TEXT_FIELD_FONT_SIZE_REM}rem`}}} // font size of input text
                spellCheck="false"
                variant="outlined"
                fullWidth
                multiline
                rows={rows}
                value={value}
                onChange={(event) => {
                    setValue(event.target.value)
                }}
                onKeyDown={handleKeyDown}
            />

            <NodeResizer
                isVisible={selected}
                minWidth={NODE_MIN_HEIGHT}
                minHeight={NODE_MIN_WIDTH}
                onResizeEnd={handleSetRow}
            />

        </div>
    );
};

export default memo(Memo);
