import * as React from "react";
import {Button, TextField, IconButton} from "@mui/material";
import {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import signin from "@api/sign/signin";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "400px",
        padding: theme.spacing(4),
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[2],
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    signUpLink: {
        marginTop: theme.spacing(2),
    },
    logoWrapper: {
        display: "flex",
        alignItems: "center",
    },
    logo: {
        width: "60px",
        marginRight: theme.spacing(1),
    },
    logoTitle: {
        fontSize: "1.5rem",
        fontWeight: "bold",
    },
    socialLoginWrapper: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
    },
    socialLoginButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        color: theme.palette.text.primary,
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        // maxWidth: '250px',
        marginBottom: theme.spacing(2),
        '& img': {
            marginRight: theme.spacing(1),
        },
        '&:hover': {
            backgroundColor: '#eee',
        },
    },
    link: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
    },
    textSecondary: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
    },
    twin: {
        // display: "flex",
        // justifyContent: "space-between",
        // marginRight: theme.spacing(1),

        display: "flex",
        borderRadius: 1,
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "stretch",
    },
}));


function Main() {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const handleSubmit = (e) => {
        signin(email, password)

        e.preventDefault();
        // 로그인 폼 제출 로직
    };

    const handleGithubLogin = () => {
        // Github 로그인 로직
        window.location.href = "/oauth2/authorization/github";
    };

    const handleGoogleLogin = () => {
        // Google 로그인 로직
        window.location.href = "/oauth2/authorization/google";
    };

    return (
        <div className={classes.root}>
            <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.logoWrapper}>
                    <img src="/img/logo/logo_black.svg" alt="logo" className={classes.logo} />
                </div>
                <div className={classes.logoWrapper}>
                    <h1 className={classes.logoTitle}>GramCod</h1>
                </div>
                <TextField
                    label="이메일"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={classes.textField}
                />
                <TextField
                    label="비밀번호"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={classes.textField}
                />

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.submitButton}
                >
                    로그인
                </Button>
                {/*<span className={classes.textSecndary}>다른 계정으로 로그인</span>*/}
                <div className={classes.socialLoginWrapper}>
                    <Button
                        className={classes.socialLoginButton}
                        // variant="contained"
                        // color="primary"
                        // type="submit"
                        // className={classes.submitButton}
                        onClick={handleGoogleLogin}
                    >
                        <img src="/img/google/web/vector/btn_google_gray.svg" alt="logo"  width={30} />
                        Google
                    </Button>
                    <Button
                        className={classes.socialLoginButton}
                        // variant="contained"
                        // color="primary"
                        // type="submit"
                        // className={classes.submitButton}

                        onClick={handleGithubLogin}
                    >
                        <img src="/img/github/github-mark-gray.svg" alt="logo" width={30} />
                        Github
                    </Button>
                </div>
            </form>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/*<Button>df</Button>*/}
                {/*<Button>df</Button>*/}
                <div style={{marginTop:"15px"}}  className={classes.signUpLink}>
                    <Link to="/signup" className={classes.link}>회원가입</Link>
                </div>
                <span style={{marginTop:"15px"}} className={classes.textSecondary}>비밀번호 찾기</span>
            </div>
        </div>
    );
}

export default Main;
