import api from '../api';
import {GrantType} from "@type/GrantType";
import jwt_decode from 'jwt-decode';
import signout from "@api/sign/signout";
import BaseException from "@exception/BaseException";
import {TokenInterface} from "@api/interface/tokenInterface";
import {TokenRequestInterface} from "@api/interface/tokenRequestInterface";


let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const signinRefresh = async (token, decodedToken) => {

    const accessToken = token?.accessToken;
    const refreshToken = token?.refreshToken;
    // const expiresIn = token?.expiresIn;
    const expiresIn = decodedToken?.exp;

    // TODO :: 시간값 확인 필요.
    // 토큰이 만료될 시간이 되면, 새로운 토큰을 발급받음
    if (expiresIn && Date.now() > expiresIn && !isRefreshing) {
        isRefreshing = true;

        const tokenReq: TokenRequestInterface = {
            grantType: GrantType.REFRESH_TOKEN,
            refreshToken: refreshToken,
        }

        await api.post('/auth/token', tokenReq)
            .then((response) => {
                if (response.status != 200) {
                    throw new BaseException("> ERR > Token Refresh")
                }

                const newToken: TokenInterface = response.data.data;

                localStorage.setItem('token', JSON.stringify(newToken));
                const decodedToken = jwt_decode(newToken.accessToken);
                localStorage.setItem('decodedToken', JSON.stringify(decodedToken));

                isRefreshing = false;
                onRefreshed(token.accessToken);
            })
            .catch((error) => {
                console.error(error);
                signout()
                isRefreshing = false;
                // 새로운 토큰 발급 실패시 로그인 페이지로 이동 또는 에러 메시지 출력
            });;
    }

};


// Refresh token subscriber
function subscribeTokenRefresh(callback: (token: string) => void) {
    refreshSubscribers.push(callback);
}

// Call refresh token subscribers
function onRefreshed(token: string) {
    refreshSubscribers.forEach((callback) => callback(token));
    refreshSubscribers = [];
}
export default signinRefresh;
