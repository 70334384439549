import "./Layout.css";
import * as React from "react";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HeightMap } from "@map/HeightMap";
import { useRecoilValue } from "recoil";
import { DarkThemeMap } from "@map/DarkThemeMap";
import ConfigState from "@recoil/ConfigState";
import Main from "@page/signup/main/Main";
import { ColorModeContext } from '@page/signup/header/ThemeToggleIconButton';
import SheetManager from "@page/signup/commons/sheetManager/SheetManager";
import Header from "@page/signup/header/Header";
import jwt_decode from 'jwt-decode';
import axios from 'axios';

export default function Layout() {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)
  const DEFAULT_THEME = 'dark'
  const [mode, setMode] = React.useState<'light' | 'dark'>(DEFAULT_THEME);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
    const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: DarkThemeMap.BUTTON.primary(mode), 
          }
        },
      }),
    [mode],
  );


    return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            overflowY: "scroll",
            height: "100vh",
            maxHeight: "100vh",
            width: "100vw"
          }}
        >
          <Header></Header>
          <Box
            sx={{
              display: "flex",
              borderRadius: 1,
              height: `calc(100vh - ${HeightMap.HEADER_HEIGHT})`,
  
              ...defaultDebugUIStyle,
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: 1,
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "stretch",

                ...defaultDebugUIStyle,
              }}
            >
              <Main />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
