import axios from 'axios';
import signinRefresh from "@api/sign/signinRefresh";
import signout from "@api/sign/signout";
import {TokenInterface} from "@api/interface/tokenInterface";

const instance = axios.create({
    baseURL: 'https://www.gramcod.com/api', // prod
    // baseURL: 'http://localhost:8080/api', // local
    headers: {
        'Content-Type': 'application/json'
    }
});

// Request interceptor
instance.interceptors.request.use(
    (config) => {
        let token: TokenInterface
        let decodedToken
        try {
            token = JSON.parse(<string>localStorage.getItem('token') || "{}")
            decodedToken = JSON.parse(<string>localStorage.getItem('decodedToken') || "{}")
        } catch (error) {
            console.error(error);
            signout()
            return config;
        }
        const accessToken = token?.accessToken;
        const refreshToken = token?.refreshToken;
        const expiresIn = token?.expiresIn;

        if (accessToken) {
            // @ts-ignore
            config.headers.Authorization = `Bearer ${accessToken}`; // 헤더에 토큰 추가
        }

        // 새로운 토큰을 발급받기 위해 서버에 요청
        signinRefresh(token, decodedToken)

        return config;
    },
    (error) => Promise.reject(error)
);



export default instance;
