import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Handle, Position, useStore } from 'reactflow';
import './Table.css';
import Sheet from './Sheet';
import { NodeResizer } from "@reactflow/node-resizer";
import './resize.css';
import './nodetype.css';
import Box from "@mui/material/Box";
import { Breadcrumbs, Button, IconButton, Link, Typography } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useRecoilState } from "recoil";
import FlowNodeState from "@recoil/FlowNodeState";
import produce from "immer";
import { FLOW_NODE_TYPE } from "@type/FlowNodeTypes";
import UuidUtil from "@util/UuidUtil";
import { DarkThemeMap } from '@map/DarkThemeMap';


interface TableInterface {
    data: {
        label: string
        flowUuid: string
        flowNodeUuid: string
        isCopy?: boolean
        orgData?: {
            flowUuid: string
            flowNodeUuid: string
        }
    }
    id: string
    selected: boolean
}

function Table(props: TableInterface) {
    const NODE_MIN_HEIGHT = 300;
    const NODE_MIN_WIDTH = 750;
    const HEADER_HEIGHT = 45;
    const { data, selected } = props;
    const label = data?.label ?? 'Table';
    const flowUuid = props.data.flowUuid
    const flowNodeUuid = props.data.flowNodeUuid

    const [nodeMinHeight, setNodeMinHeight] = useState(NODE_MIN_HEIGHT);
    const [nodeHeight, setNodeHeight] = useState(NODE_MIN_HEIGHT);
    const inputRef = useRef<HTMLDivElement>(null);
    const nodeRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const inputHeightRef = inputRef.current?.clientHeight ?? 0;
    const nodeHeightRef = nodeRef.current?.clientHeight ?? 0;
    const [flowNode, updateflowNode] = useRecoilState(FlowNodeState.update)
    const [currRows, setCurrRows] = useState(0);
    const [minRows, setMinRows] = useState(0);
    const [maxRows, setMaxRows] = useState(0);
    const CELL_DEFAULT_HEIGHT = 12

    const size = useStore((s) => {
        const node = s.nodeInternals.get(props.id);

        return {
            width: node?.width ?? NODE_MIN_WIDTH,
            height: node?.height ?? NODE_MIN_HEIGHT,
        };
    });


    useEffect(() => {
        const orgGrid = flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["grid"]
        if (orgGrid === undefined) {
            return

        }
        const inputHeightRef = inputRef.current?.clientHeight ?? 0;
        const rowHeight = Math.floor(inputHeightRef / orgGrid.length)

        const reverseOrgGrid = [...orgGrid].reverse()
        let countEmptyString = 0
        for (let i = 0; i < reverseOrgGrid.length; i++) {
            const row = reverseOrgGrid[i]
            const isAllEmpty = row.every(cell => cell.value === '')
            if (isAllEmpty) {
                countEmptyString++
            } else {
                break
            }
        }

        const minRows = orgGrid.length - countEmptyString;
        const currMinHeight = rowHeight * minRows + HEADER_HEIGHT + rowHeight
        setNodeMinHeight(currMinHeight);
    }, [flowUuid, flowNodeUuid, inputRef.current?.clientHeight])


    useEffect(() => {
        const orgGrid = flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["grid"]
        if (orgGrid === undefined) {
            return
        }

        const currRows = orgGrid.length
        setCurrRows(currRows);
    }, [flowUuid, flowNodeUuid, size.width, size.height])



    useEffect(() => {
        const maxRows = Math.floor((size.height - HEADER_HEIGHT) / CELL_DEFAULT_HEIGHT)
        setMaxRows(maxRows);
    }, [size.width, size.height])

    useEffect(() => {
        const orgGrid = flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["grid"]
        if (orgGrid === undefined) {
            return
        }

        const reverseOrgGrid = [...orgGrid].reverse()
        let countEmptyString = 0
        for (let i = 0; i < reverseOrgGrid.length; i++) {
            const row = reverseOrgGrid[i]
            const isAllEmpty = row.every(cell => cell.value === '')
            if (isAllEmpty) {
                countEmptyString++
            } else {
                break
            }
        }
        const minRows = orgGrid.length - countEmptyString;
        setMinRows(minRows);
    }, [flowUuid, flowNodeUuid, size.width, size.height])



    useEffect(() => {
        const orgGrid = flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["grid"]
        if (orgGrid === undefined) {
            return
        }

        const diff = maxRows - currRows - 2
        if (diff <= 0) {
            return
        }

        const inputHeightRef = inputRef.current?.clientHeight ?? 0;
        const rowHeight = Math.floor(inputHeightRef / orgGrid.length)
        const ableAddRows = Math.floor((size.height - inputHeightRef - HEADER_HEIGHT) / rowHeight)

        // console.log(`real`, rowHeight)
        // console.log(`real2`, ableAddRows)

        if (ableAddRows <= 1) {
            return
        }

        let newGrid;
        newGrid = produce(orgGrid, draft => {
            for (let index = 0; index < ableAddRows; index++) {
                const DEFAULT_ARRAY = [
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                    { value: '' },
                ]
                draft.push(DEFAULT_ARRAY)
            }
        })

        updateflowNode({
            flowMap: {
                [flowUuid]: {
                    [flowNodeUuid]: {
                        type: FLOW_NODE_TYPE.TABLE,
                        uuid: "string",
                        name: "string",
                        data: {
                            grid: newGrid
                        },
                    }
                }
            }
        })

    }, [flowUuid, flowNodeUuid, currRows, minRows, maxRows])


    useEffect(() => {
        const orgGrid = flowNode.flowMap?.[flowUuid]?.[flowNodeUuid]?.["data"]?.["grid"]
        if (orgGrid === undefined) {
            return
        }

        // const diff = maxRows - currRows - 2
        // if (diff > 0) {
        //     return
        // }

        const inputHeightRef = inputRef.current?.clientHeight ?? 0;
        const rowHeight = Math.floor(inputHeightRef / orgGrid.length)
        const ableAddRows = Math.floor((size.height - inputHeightRef - HEADER_HEIGHT) / rowHeight)

        if (ableAddRows > 0) {
            return
        }

        const reverseOrgGrid = [...orgGrid].reverse()
        let countEmptyString = 0
        for (let i = 0; i < reverseOrgGrid.length; i++) {
            const row = reverseOrgGrid[i]
            const isAllEmpty = row.every(cell => cell.value === '')
            if (isAllEmpty) {
                countEmptyString++
            } else {
                break
            }
        }

        const ableDecRows = Math.min(Math.abs(ableAddRows), countEmptyString)

        // console.log(`real`, ableAddRows)
        // console.log(`real2`, Math.max(0, orgGrid.length - countEmptyString))
        // console.log(`real23`, countEmptyString, ableDecRows, orgGrid)


        if (ableDecRows === 0) {
            return
        }
        // console.log(`ableDecRows`, ableDecRows)

        let newGrid;
        newGrid = produce(orgGrid, draft => {
            draft.splice(orgGrid.length - ableDecRows, ableDecRows)
        })
        // console.log(`fffff`, newGrid)
        // console.log(`fffff`, newGrid)

        updateflowNode({
            flowMap: {
                [flowUuid]: {
                    [flowNodeUuid]: {
                        type: FLOW_NODE_TYPE.TABLE,
                        uuid: "string",
                        name: "string",
                        data: {
                            grid: newGrid
                        },
                    }
                }
            }
        })

    }, [flowUuid, flowNodeUuid, currRows, minRows, maxRows])


    const nodeStyle = {
        backgroundColor: "rgba(240, 240, 240, 0.25)",
        minHeight: `${nodeMinHeight - 10}px`,
        minWidth: `${NODE_MIN_WIDTH}px`,
        height: `100%`,
        width: `100%`,
    }
    const headerStyle = {
        height: `${HEADER_HEIGHT}px`,
        borderBottom: `1px solid #e0e0e0`,
    }

    return (
        <div
            ref={nodeRef}
            className="text-updater-node nodetype-group white-theme"
            style={{ ...nodeStyle }}
        >

            <div style={{ ...headerStyle }}>
                <Box
                    sx={{ display: 'flex', borderRadius: 1 }}
                >
                    <Box>
                        <IconButton>
                            <TableChartIcon style={{ fontSize: '1.5rem' }} />
                        </IconButton>
                        {/* <IconButton>
                            <EditIcon style={{ fontSize: '1.5rem' }} />
                        </IconButton> */}


                        <Breadcrumbs aria-label="breadcrumb"
                            // separator=">" 
                            sx={{ display: "inline-block" }}
                        >
                            <Button
                                className="nodrag"
                                sx={{
                                    color: DarkThemeMap.TAB.color,
                                    fontSize: "1.25rem",
                                    fontWeight: "700",
                                }}
                            >
                                테스트
                            </Button>

                            <Button
                                className="nodrag"
                                sx={{
                                    color: DarkThemeMap.TAB.color,
                                    fontSize: "1.25rem",
                                    fontWeight: "700",
                                }}
                            >
                                Test
                            </Button>
                        </Breadcrumbs>

                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                    </Box>
                    <Box>
                        <IconButton>
                            <CloseIcon style={{ fontSize: '1.5rem' }} />
                        </IconButton>
                    </Box>
                </Box>
            </div>
            {/* <div>
            <label htmlFor="text">Text:</label>
            <input id="text" name="text" onChange={onChange} />
          </div> */}
            {/*{inputHeightRef}*/}

            <div
                ref={inputRef}
            >
                {/* <TestDataGrid direction='ltr'></TestDataGrid> */}
                {/* <TestGridComp /> */}
                {/* <TestSpread></TestSpread> */}
                <Sheet
                    flowUuid={flowUuid}
                    flowNodeUuid={flowNodeUuid}
                />
            </div>
            {/*<Handle type="target" position={Position.Top}/>*/}
            {/*<Handle type="source" position={Position.Bottom} id="a" style={handleStyle}/>*/}
            {/*<Handle type="source" position={Position.Bottom} id="b"/>*/}

            <NodeResizer
                isVisible={selected}
                minWidth={NODE_MIN_WIDTH}
                minHeight={nodeMinHeight}
            // onResize={()=>setNodeHeight(inputHeightRef + HEADER_HEIGHT)}

            // onResizeStart={e => console.log(`start`, e)}
            // onResizeEnd={e => console.log(`end`, e)}
            // onResizeStart={e => console.log(`start`, e.sourceEvent.screenY)}
            // onResizeEnd={e => console.log(`end`, e.sourceEvent.screenY)}
            // onResizeStart={e => console.log(`start`, e.sourceEvent)}
            // onResizeEnd={e => console.log(`end`, e.sourceEvent)}
            // onResizeEnd={()=>onNodeHeightChangedZero(nodeHeight)}
            // onResize={onNodeHeightChangedZero}

            // onResizeEnd={(e) => {return onNodeHeightChanged(rows)}}
            // onResizeEnd={(e) => {alert(JSON.stringify(e)); alert(JSON.stringify(e.y - e.subject.y))}}
            />
        </div>
    );
}

export default Table;
