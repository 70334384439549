import ProjectInterface from '@interface/ProjectInterface';
import FlowInterface from '@interface/FlowInterface';
import UuidUtil from '@util/UuidUtil';
import DateUtil from '@util/DateUtil';
import { FLOW_NODE_TYPE } from '@type/FlowNodeTypes';
import { atom, RecoilValueReadOnly, selector } from 'recoil';
import produce from 'immer';

export default class ProjectState {
  private static className: string = 'ProjectState'

  constructor() {
    if (this instanceof ProjectState) {
      throw Error('A static class cannot be instantiated.');
    }
  }

  static getKey = (key: string): string => {
    return `${ProjectState.className}/${key}`
  }

  static getInitFlowData = (): FlowInterface => {
    const flowDataUuid = UuidUtil.getUuid()
    const initData: FlowInterface = {
      uuid: flowDataUuid,
      sort: 0,
      version: DateUtil.getCurrentUnixTime(),
      rgstYmdHis: DateUtil.getCurrentDateTime(),
      modYmdHis: DateUtil.getCurrentDateTime(),
      name: DateUtil.getCurrentDateTime(),
      isEdit: false,
      isVisible: true,
      render: {
        nodes: [
          { id: '21',
            type: FLOW_NODE_TYPE.TABLE,
            data: {
              label: '2',
              flowUuid: flowDataUuid,
              flowNodeUuid: '21',
            },
            position: { x: 0, y: 100 },
          },
          {
            id: '2',
            type: FLOW_NODE_TYPE.MEMO,
            data: {
              label: 'memo',
              flowUuid: flowDataUuid,
              flowNodeUuid: '1q',
            },
            position: { x: 200, y: 200 },
            zIndex: -5,
          },
          {
            id: '3',
            type: FLOW_NODE_TYPE.GROUP,
            data: {
              label: '1aa',
              flowUuid: flowDataUuid,
              flowNodeUuid: '1a',
            },
            position: { x: 500, y: 500 },
            zIndex: -10,
          },
        ],
        edges: [
          // { id: 'e1-2', source: '1', target: '2' }
        ],
        viewport: {
          x: 0,
          y: 0,
          zoom: 1,
        }
      }
    }

    return initData
  }

  static getTestFlowDataGroup = (): { [key: string]: FlowInterface } => {
    const data1 = ProjectState.getInitFlowData()
    data1.name = `true001`
    const data2 = ProjectState.getInitFlowData()
    data2.name = `true002`
    data2.isVisible = false
    const data3 = ProjectState.getInitFlowData()
    data3.name = `true003`
    data3.isVisible = false

    const testFlowDataGroup = {
      [data1.uuid]: data1,
      [data2.uuid]: data2,
      [data3.uuid]: data3,
    }

    return testFlowDataGroup
  }

  static getUuid = (projectInfoDataState: ProjectInterface): string => {
    const maxCnt = 10
    let uuid = UuidUtil.getUuid()
    for (let i = 0; i < maxCnt; i++) {
      if (projectInfoDataState.flowMap[uuid] === undefined) {
        break
      }

      uuid = UuidUtil.getUuid()
    }

    return uuid
  }

  static updateVersionProjectInfoData = (projectInfo: ProjectInterface) => {
    const newProjectInfo = produce(projectInfo, draft => {
      draft.version = DateUtil.getCurrentUnixTime()
      draft.modYmdHis = DateUtil.getCurrentDateTime()
    })

    return newProjectInfo
  }

  static updateVersionFlowData = (flowData: FlowInterface) => {
    const newFlowData = produce(flowData, draft => {
      draft.version = DateUtil.getCurrentUnixTime()
      draft.modYmdHis = DateUtil.getCurrentDateTime()
    })

    return newFlowData
  }

  // # recoil
  static projectInfo = atom<ProjectInterface>({
    key: ProjectState.getKey('projectInfoDataState'),
    default: {
      name: "New Project",
      version: DateUtil.getCurrentUnixTime(),
      rgstYmdHis: DateUtil.getCurrentDateTime(),
      modYmdHis: DateUtil.getCurrentDateTime(),
      flowMap: ProjectState.getTestFlowDataGroup(),
    },
  })

  static string: RecoilValueReadOnly<string> = selector({
    key: ProjectState.getKey('string'),
    get: ({ get }) => (JSON.stringify(get(ProjectState.projectInfo))),
  });

  static prettierString: RecoilValueReadOnly<string> = selector({
    key: ProjectState.getKey('prettierString'),
    get: ({ get }) => (JSON.stringify(get(ProjectState.projectInfo), null, 2)),
  });

  static flowDataListOrderByRgst: RecoilValueReadOnly<Array<FlowInterface>> = selector({
    key: ProjectState.getKey('flowDataListOrderByRgst'),
    get: ({ get }) => Object
      .values(get(ProjectState.projectInfo).flowMap)
      .sort((a, b) =>
        parseInt(b.rgstYmdHis)
        - parseInt(a.rgstYmdHis)
      ),
  });

  static flowDataList: RecoilValueReadOnly<Array<FlowInterface>> = selector({
    key: ProjectState.getKey('flowDataList'),
    get: ({ get }) => Object.values(get(ProjectState.projectInfo).flowMap),
  });

  static flowMap: RecoilValueReadOnly<{ [key: string]: FlowInterface }> = selector({
    key: ProjectState.getKey('flowMap'),
    get: ({ get }) => get(ProjectState.projectInfo).flowMap,
  });

  static createFlowDataList = selector({
    key: ProjectState.getKey('createFlowDataList'),
    get: ({ get }) => get(ProjectState.flowDataList),
    set: ({ get, set }, pFlowDataList) => {

      const projectInfoData: ProjectInterface = get(ProjectState.projectInfo)
      const newflowMap: { [key: string]: FlowInterface } = { ...projectInfoData.flowMap }
      Object.values(pFlowDataList).forEach(
        (flowData: FlowInterface) => {
          newflowMap[flowData.uuid] = flowData
        }
      )
      const newProjectInfo = ProjectState.updateVersionProjectInfoData(projectInfoData)

      return set(
        ProjectState.projectInfo, {
        ...newProjectInfo,
        flowMap: {
          ...newflowMap
        }
      }
      )
    },
  });

  static updateFlowDataList = selector({
    key: ProjectState.getKey('updateFlowDataList'),
    get: ({ get }) => get(ProjectState.flowDataList),
    set: ({ get, set }, pFlowDataList) => {
      // console.log(`updateFlowDataList`)
      // console.log(JSON.stringify(pFlowDataList))
      const projectInfoData: ProjectInterface = get(ProjectState.projectInfo)
      const newflowMap: { [key: string]: FlowInterface } = { ...projectInfoData.flowMap }
      Object.values(pFlowDataList).forEach(
        (flowData: FlowInterface) => {
          newflowMap[flowData.uuid] = ProjectState.updateVersionFlowData(flowData)
        }
      )
      const newProjectInfo = ProjectState.updateVersionProjectInfoData(projectInfoData)

      return set(
        ProjectState.projectInfo, {
        ...newProjectInfo,
        flowMap: {
          ...newflowMap
        }
      }
      )
    },
  });

  static updateFlowDataRenderList = selector({
    key: ProjectState.getKey('updateFlowDataRenderList'),
    get: ({ get }) => get(ProjectState.flowDataList),
    set: ({ get, set }, pFlowDataList) => {
      // console.log(`updateFlowDataRenderList`)
      // console.trace(`JSON.stringify(pFlowDataList)` + JSON.stringify(pFlowDataList))
      const projectInfoData: ProjectInterface = get(ProjectState.projectInfo)
      const flowMap = projectInfoData.flowMap
      const newflowMap: { [key: string]: FlowInterface } = { ...flowMap }
      Object.values(pFlowDataList).forEach(
        (pFlowData: FlowInterface) => {
          const updated = ProjectState.updateVersionFlowData(flowMap[pFlowData.uuid])
          const newFlowData = produce(updated, draft => {
            draft.render = pFlowData.render
          })

          newflowMap[pFlowData.uuid] = newFlowData
        }
      )
      const newProjectInfo = ProjectState.updateVersionProjectInfoData(projectInfoData)

      return set(
        ProjectState.projectInfo, {
        ...newProjectInfo,
        flowMap: {
          ...newflowMap,
        }
      }
      )
    },
  });

  static deleteFlowDataList = selector({
    key: ProjectState.getKey('deleteFlowDataList'),
    get: ({ get }) => get(ProjectState.flowDataList),
    set: ({ get, set }, pflowMap) => {

      const projectInfoData = get(ProjectState.projectInfo)
      const newflowMap: { [key: string]: FlowInterface } = { ...projectInfoData.flowMap }
      Object.values(pflowMap).forEach(
        (flowData: FlowInterface) => {
          delete newflowMap[flowData.uuid]
          return
        }
      )
      const newProjectInfo = ProjectState.updateVersionProjectInfoData(projectInfoData)

      return set(
        ProjectState.projectInfo, {
        ...newProjectInfo,
        flowMap: {
          ...newflowMap
        }
      }
      )
    },
  });

  static disableAllViewFlowDataList = selector({
    key: ProjectState.getKey('disableAllViewFlowDataList'),
    get: ({ get }) => { return },
    set: ({ get, set }, pflowMap) => {

      const projectInfoData = get(ProjectState.projectInfo)
      const newflowMap: { [key: string]: FlowInterface } = { ...projectInfoData.flowMap }
      Object.values(newflowMap).forEach(
        (flowData: FlowInterface) => {
          const newFlowData = produce(flowData, draft => {
            draft.isVisible = false
          })
          newflowMap[flowData.uuid] = ProjectState.updateVersionFlowData(newFlowData)
        }
      )
      const newProjectInfo = ProjectState.updateVersionProjectInfoData(projectInfoData)

      return set(
        ProjectState.projectInfo, {
        ...newProjectInfo,
        flowMap: {
          ...newflowMap
        }
      }
      )
    },
  });

}
