import * as React from "react";
import Box from "@mui/material/Box";
import { HeightMap } from "@map/HeightMap";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { ThemeToggleIconButton } from './ThemeToggleIconButton';
import AddIcon from '@mui/icons-material/Add';
import ConfigState from "@recoil/ConfigState";
import { useRecoilValue } from "recoil";
import { DarkThemeMap } from "@map/DarkThemeMap";
import { Button, Tooltip } from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import Folder from '@mui/icons-material/Folder';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ShareIcon from '@mui/icons-material/Share';
import LanguageIcon from '@mui/icons-material/Language';
import Scrollbar from "react-scrollbars-custom";

function Header() {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)
  const HEADER_HEIGHT: string = HeightMap.HEADER_HEIGHT

  const createTab = () => {
    alert(1)
  }

  return (
    <Box
      sx={{

        display: "flex",
        height: HEADER_HEIGHT,
        bgcolor: DarkThemeMap.SIDE.bgcolor,
        borderBottom: DarkThemeMap.SIDE.border,
        borderBottomColor: DarkThemeMap.SIDE.borderColor,

        ...defaultDebugUIStyle,
      }}
    >
      <Box sx={{
        width: `calc(100vw / 2)`,
        display: "flex",
        flexDirection: "row",
      }}>

        <Scrollbar noScrollY>

          <Box
            sx={{
              minWidth: `calc(100vw / 2)`,
              display: "flex",
              flexDirection: "row",
              flexGrow: 1
            }}>
              
            <Button sx={{ textTransform: 'none', paddingLeft: "20px", paddingRight: "20px", whiteSpace: "nowrap" }} onClick={createTab}>
              {/*<AcUnitIcon />*/}
              <img src="/img/logo/logo_white.svg" alt="logo" width="25px" />
              {/*<img src="/img/logo/logo_black.svg" alt="logo" width="25px" style={{fill:"red"}}/>*/}
              {/*<img src="/img/logo/logo_gold_250_250.png" alt="logo" width="25px" />*/}
              GramCod
            </Button>
            <Tooltip title="ReName" placement="bottom">
              <Button sx={{ paddingLeft: "20px", paddingRight: "20px", whiteSpace: "nowrap"  }} onClick={createTab}>
                <Folder /> Project 이름
              </Button>
            </Tooltip>
          </Box>
        </Scrollbar>
      </Box >

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Scrollbar noScrollY>

          <Box
            sx={{
              minWidth: `calc(100vw / 2 - 5px)`,
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            
            
            <Tooltip title="Account" placement="bottom">
              <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
                <AccountCircleIcon />
              </Button>
            </Tooltip>
              
            <Tooltip title="Settings" placement="bottom">
              <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
                <SettingsIcon />
              </Button>
            </Tooltip>

            <ThemeToggleIconButton />

            <Tooltip title="Language" placement="bottom">
              <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
                <LanguageIcon />
              </Button>
            </Tooltip>


            <Tooltip title="공유" placement="bottom">
              <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
                <ShareIcon />
              </Button>
            </Tooltip>
            <Tooltip title="사본 만들기" placement="bottom">
              <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
                <FolderCopyIcon />
              </Button>
            </Tooltip>
            <Tooltip title="새 프로젝트" placement="bottom">
              <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
                <CreateNewFolderIcon />
              </Button>
            </Tooltip>

          </Box>
        </Scrollbar>

      </Box>
    </Box >
  );
}

export default Header;
