import * as React from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Tooltip } from '@mui/material';

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

export function ThemeToggleIconButton(props: any) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  return (
    <Tooltip title="Light / Dark" placement="bottom">
      <Button
        sx={{ paddingLeft: "20px", paddingRight: "20px" }}
        onClick={colorMode.toggleColorMode} >
        {theme.palette.mode === 'dark'
          ? <Brightness7Icon {...props} />
          : <Brightness4Icon {...props} />}
      </Button>
    </Tooltip>
  );
}
