import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Tooltip } from '@mui/material';
import { HeightMap } from "@map/HeightMap";
import { useRecoilValue } from "recoil";
import ConfigState from "@recoil/ConfigState";
import { DarkThemeMap } from "@map/DarkThemeMap";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableViewIcon from '@mui/icons-material/TableView';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import Scrollbar from "react-scrollbars-custom";

function SheetManager() {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)
  const FOOTER_HEIGHT: string = HeightMap.FOOTER_HEIGHT

  const createTab = () => {
    alert(1)
  }

  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          height: FOOTER_HEIGHT,

          bgcolor: DarkThemeMap.SIDE.bgcolor,
          // borderTop: DarkThemeMap.SIDE.border,
          borderTopColor: DarkThemeMap.SIDE.borderColor,

          ...defaultDebugUIStyle,
        }}
      >

        <Scrollbar noScrollY={true} >

          <Tooltip title="Diagram" placement="top">
            <Button variant="outlined" sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
              <AccountTreeIcon />
            </Button>
          </Tooltip>

          <Tooltip title="Table" placement="top">
            <Button sx={{ textAlign: "left", paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
              <TableChartIcon />
            </Button>
          </Tooltip>

          <Tooltip title="Group" placement="top">
            <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
              <TableViewIcon />
            </Button>
          </Tooltip>

          <Tooltip title="UI" placement="top">
            <Button sx={{ paddingLeft: "20px", paddingRight: "20px" }} onClick={createTab}>
              <AutoAwesomeIcon />
            </Button>
          </Tooltip>

          <Button onClick={createTab} size="small">
            <DownloadIcon /> Import
          </Button>

          <Button onClick={createTab} size="small">
            <UploadIcon /> Export
          </Button>
        </Scrollbar>
      </Box>
    </>
  );
}

export default SheetManager;
