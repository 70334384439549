import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Divider, List, Tooltip } from '@mui/material';
import ConfigState from "@recoil/ConfigState";
import { useRecoilValue } from "recoil";
import { WidthMap } from "@map/WidthMap";
import { DarkThemeMap } from "@map/DarkThemeMap";
import ProjectState from "@recoil/ProjectState";
import FlowFunctionState from "@recoil/FlowFunctionState";
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import SearchIcon from '@mui/icons-material/Search';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableViewIcon from '@mui/icons-material/TableView';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CableIcon from '@mui/icons-material/Cable';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Scrollbar from "react-scrollbars-custom";
import HistoryIcon from '@mui/icons-material/History';
import ArchiveIcon from '@mui/icons-material/Archive';
import GitHubIcon from '@mui/icons-material/GitHub';
import CommitIcon from '@mui/icons-material/Commit';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { HeightMap } from "@map/HeightMap";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
 
function Left(props: any) {
  const defaultDebugUIStyle = useRecoilValue(ConfigState.defaultDebugUIStyle)
  const projectState = useRecoilValue(ProjectState.projectInfo)
  const flowFunctionState = useRecoilValue(FlowFunctionState.atom)

  const selectedFlow = Object.values(projectState.flowMap).filter(e => e.isVisible === true).pop()
  const selectedUuid = selectedFlow?.uuid

  const addTableNode = () => {
    if (!selectedUuid) {
      return
    }

    flowFunctionState.flowMap[selectedUuid].addTableNode()
  }

  const addMemoNode = () => {
    if (!selectedUuid) {
      return
    }

    flowFunctionState.flowMap[selectedUuid].addMemoNode()
  }

  const addGroupNode = () => {
    if (!selectedUuid) {
      return
    }

    flowFunctionState.flowMap[selectedUuid].addGroupNode()
  }

  const createTab = () => {
    alert(1)
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          bgcolor: DarkThemeMap.SIDE.bgcolor,
          borderRight: DarkThemeMap.SIDE.border,
          borderRightColor: DarkThemeMap.SIDE.borderColor,

          ...defaultDebugUIStyle,
        }}
      >

        <Box
          sx={{
            height: `calc((100vh - ${HeightMap.HEADER_HEIGHT}) / 2 - 1px)`,
            display: "flex",
            flexDirection: "column",
            maxWidth: WidthMap.LEFT_MAX_WIDTH,
            minWidth: WidthMap.LEFT_MIN_WIDTH,

            ...defaultDebugUIStyle,
          }}
        >

          <Scrollbar noScrollX>

            <Tooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{"선택\n( alt + 1 )"}</span>}
              placement="right"
            >
              <Button variant="outlined" fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <PanToolAltIcon />
              </Button>
            </Tooltip>

            <Tooltip title="다중선택" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <BorderClearIcon />
              </Button>
            </Tooltip>

            <Tooltip title="이동" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <OpenWithIcon />
              </Button>
            </Tooltip>

            <Tooltip title="테이블 추가" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={addTableNode}>
                <TableChartIcon />
              </Button>
            </Tooltip>

            <Tooltip title="그룹 추가" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={addGroupNode}>
                <TableViewIcon />
              </Button>
            </Tooltip>

            <Tooltip title="관계 추가" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <CableIcon />
              </Button>
            </Tooltip>

            <Tooltip title="메모 추가" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={addMemoNode}>
                <NoteAltIcon />
              </Button>
            </Tooltip>

            <Divider />
            <Divider />

            <Tooltip title="복사" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <ContentCopyIcon />
              </Button>
            </Tooltip>

            <Tooltip title="붙여넣기" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <ContentPasteIcon />
              </Button>
            </Tooltip>

            <Tooltip title="검색" placement="right">
              <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                <SearchIcon />
              </Button>
            </Tooltip>


          </Scrollbar>

        </Box>


        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            maxWidth: WidthMap.LEFT_MAX_WIDTH,
            minWidth: WidthMap.LEFT_MIN_WIDTH,

            ...defaultDebugUIStyle,
          }}

        >

          <Scrollbar noScrollX>
            <Box
              sx={{
                minHeight: `calc((100vh - ${HeightMap.HEADER_HEIGHT}) / 2 - 5px)`,
                display: "flex",
                flexDirection: "column-reverse",
                maxWidth: WidthMap.LEFT_MAX_WIDTH,
                minWidth: WidthMap.LEFT_MIN_WIDTH,
                overflowY: "hidden",

                ...defaultDebugUIStyle,
              }}
            >

              <Tooltip title="전체화면" placement="right">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <FullscreenIcon />
                </Button>
              </Tooltip>

              <Tooltip title="보통 크기" placement="right">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <ZoomOutMapIcon />
                </Button>
              </Tooltip>

              <Tooltip title="축소" placement="right">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <ZoomOutIcon />
                </Button>
              </Tooltip>

              <Tooltip title="확대" placement="right">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <ZoomInIcon />
                </Button>
              </Tooltip>

              <Tooltip title="미니맵" placement="right">
                <Button fullWidth sx={{ minWidth: 0 }} onClick={createTab}>
                  <LocationOnIcon />
                </Button>
              </Tooltip>

            </Box>

          </Scrollbar>

        </Box>

      </Box >
    </>
  );
}

export default Left;
